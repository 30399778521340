import React from "react";
import {
  Dialog as MaterialDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Breakpoint,
} from "@mui/material";
import { CloseRounded, EmojiObjects } from "@mui/icons-material";
import { Box } from "@mui/system";
import { grey } from "@mui/material/colors";
import theme from "../../theme/theme";

interface IDialogMediumProps {
  title?: string | React.ReactNode;
  open: boolean;
  handleClose: () => boolean | void;
  children: React.ReactNode;
  icon?: React.ReactNode;
  maxWidth?: Breakpoint | false;
  fullWidth?: boolean;
  fullScreen?: boolean;
  props?: any;
}

function DialogMedium({
  title,
  open,
  handleClose,
  children,
  icon,
  maxWidth,
  fullWidth,
  fullScreen = false,
  ...props
}: IDialogMediumProps) {
  return (
    <MaterialDialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      sx={{
        padding: 1,
        "& .MuiDialogTitle-root": {
          p: 2,
        },
      }}
      {...props}
    >
      <DialogTitle>
        <Box
          sx={{
            width: "100%",
            paddingY: 0.3,
            paddingX: 1,
            borderRadius: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            display={"flex"}
            sx={{ backgroundColor: grey[50] }}
            alignItems={"center"}
          >
            <EmojiObjects
              transition
              sx={{ color: (theme) => theme.palette.primary.main }}
              component={null}
            />
            <Typography fontSize={11} color={theme.palette.secondary.main}>
              Atalho: Utilize a tecla{" "}
              <Typography fontWeight="bold" component="span" fontSize={11}>
                Esc
              </Typography>{" "}
              para fechar o modal.
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ marginTop: 2, mb: 2 }}>
          <Typography
            color="secondary"
            fontWeight="500"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }} gap={2}>
              <Box>{title}</Box>
              <Box>{icon}</Box>
            </Box>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -3,
          "&.MuiDialogContent-root": {
            padding: 2,
          },
          position: "relative",
        }}
      >
        {children}
      </DialogContent>
    </MaterialDialog>
  );
}

export default DialogMedium;
