import React, { useCallback, useState } from "react";
// redux e saga
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

//MUI
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
//IMG or SVG
import QRCodeIcon from "../../Icons/QrCode";
import PatientIcon from "../../Icons/Patient";

//style Components
import {
  ContainerStyle as Container,
  BoxIcon,
  StyledIconButton,
} from "./styles";
import { checkViewPermission } from "../../../utils/permissions";
import { toast } from "react-toastify";
import theme from "../../../theme/theme";
import RequestOpinionIcon from "@mui/icons-material/ContentPasteGo";
import TelemedicineIcon from "../../Icons/Telemedicine";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { Check, VideoCall, Videocam } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LOCALSTORAGE from "../../../helpers/constants/localStorage";

interface IRows {
  name: string;
  value: any;
}

interface IContent {
  tittle: string;
  rows: IRows[];
  // qrCodeState: QrCodeState;
  // careState: CareState;
  qrCodeState?: any;
  careState?: any;
  setCreateOpinion?: () => any;
  telemedicine?: boolean;
  onClickTelemedicine?: () => void;
  onClickJoinMeet?: (attendance?: any) => void;
  onClickFinish?: (attendance?: any) => void;
  onClickCopy?: (attendance?: any) => void;
  onClickCreateMeet?: (attendance?: any) => void;
}

type ActionType = "meet" | "copy" | "finish" | "create";

interface IProps {
  content: IContent;
  attendance?: any;
  allergic?: boolean;
  integration?: any;
  opinion?: "opinion" | "opinionS" | "revaluation" | null | undefined;
  attendanceType?: "telemedicine" | null;
  actionsTypes?: ActionType[];
  actions?: boolean;
  disableActions?: (attendance: any, action: ActionType) => boolean;
}

export default function HeaderOverview(props: IProps) {
  const {
    content,
    allergic,
    integration,
    opinion,
    attendance,
    attendanceType,
    disableActions,
    actionsTypes,
    actions,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [copiedText, setCopiedText] = useState<string>("");
  const telemedicineUrl = localStorage.getItem(LOCALSTORAGE.TELEMEDICINE_URL);
  const rightsOfLayoutState = useSelector(
    (state: ApplicationState) => state.layout.data.rights
  );

  const revaluationState = useSelector(
    (state: ApplicationState) => state.revaluation
  );

  const handleClickOpen = () => {
    checkViewPermission("qrcode", JSON.stringify(rightsOfLayoutState))
      ? setOpenDialog(true)
      : toast.error("Você não tem permissão para visualizar o QR Code");
  };

  function handlerCID(rows: IRows[]) {
    const cid = rows.filter(({ name, value }: IRows) => {
      if (name === "CID" && value) return value;
    });
    return cid.length > 0 ? cid[0].value : "Não informado";
  }

  function handlerSpeciality(rows: IRows[]) {
    const speciality = rows.filter(({ name, value }: IRows) => {
      if (name === "Especialidade do Atendimento" && value) return value;
    });
    return speciality.length > 0 ? speciality[0].value : "Não informado";
  }

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "14px",
        }}
      >
        <Box>
          <PatientIcon
            fill={theme.palette.primary.main}
            width={"48px"}
            height={"48px"}
          />
        </Box>

        <Box>
          <Typography
            className="name"
            fontSize={18}
            color={theme.palette.common.white}
            fontWeight={700}
          >
            {content.rows.map(
              ({ name, value }: IRows, index: number) =>
                name === "Nome" && value
            )}
          </Typography>

          <Typography fontSize={12} color={theme.palette.common.white}>
            CID {handlerCID(content.rows)}
          </Typography>

          {(opinion === "opinion" || opinion === "opinionS") && (
            <Typography fontSize={12} color={theme.palette.common.white}>
              Especialidade do Atendimento: {handlerSpeciality(content.rows)}
            </Typography>
          )}
        </Box>
      </Box>

      {opinion === "opinionS" && (
        <Box sx={{ display: "flex" }}>
          <BoxIcon
            onClick={() => content.setCreateOpinion()}
            sx={{
              paddingTop: "4px",
              backgroundColor: theme.palette.primary.light,
              width: "10rem",
            }}
          >
            <RequestOpinionIcon sx={{ color: theme.palette.common.white }} />
            <Typography
              sx={{ cursor: "pointer" }}
              fontWeight={900}
              fontSize="0.75rem"
              color={theme.palette.common.white}
            >
              NOVA SOLICITAÇÃO
            </Typography>
          </BoxIcon>
        </Box>
      )}

      {opinion === "opinion" && (
        <Box sx={{ display: "flex" }}>
          <BoxIcon
            onClick={() => content.onClickTelemedicine()}
            sx={{
              paddingTop: "4px",
              backgroundColor: theme.palette.primary.light,
              width: "10rem",
            }}
          >
            <TelemedicineIcon
              fill={theme.palette.common.white}
              width={"2.1rem"}
              height={"2.1rem"}
            />
            <Typography
              sx={{ cursor: "pointer" }}
              fontWeight={600}
              fontSize="0.75rem"
              color={theme.palette.common.white}
            >
              ENTRAR NA SALA
            </Typography>
          </BoxIcon>
        </Box>
      )}

      {opinion === "revaluation" && (
        <Box sx={{ display: "flex" }}>
          <Tooltip
            title={
              attendance?.status === "available" ||
              attendance?.status === "inProgress"
                ? "Copiar link da sala"
                : "Gerar link para o paciente"
            }
          >
            <StyledIconButton disabled={!!disableActions && disableActions(attendance, "copy")} onClick={() => content.onClickCopy()}>
              {attendance?.status === "available" ||
              attendance?.status === "inProgress" ? (
                <CopyToClipboard
                  text={`${telemedicineUrl}/R${attendance.id_attendance}`}
                >
                  <ContentCopy />
                </CopyToClipboard>
              ) : (
                <VideoCall />
              )}
            </StyledIconButton>
          </Tooltip>
          <Tooltip title="Entrar na sala">
            <StyledIconButton
              onClick={() => content.onClickJoinMeet()}
              disabled={!!disableActions && disableActions(attendance, "meet")}
            >
              <Videocam />
            </StyledIconButton>
          </Tooltip>
          <Tooltip title="Finalizar Atendimento">
            <StyledIconButton
              sx={{ color: theme.palette.success.main }}
              onClick={() => content.onClickFinish()}
              disabled={!!disableActions && disableActions(attendance, "finish")}
            >
              <Check />
            </StyledIconButton>
          </Tooltip>
        </Box>
      )}

      {attendanceType === "telemedicine" && actions && (
        <Box sx={{ display: "flex", gap: 2 }}>
          {actionsTypes.map((item) => {
            switch (item) {
              case "create":
                return (
                  <Tooltip title={"Criar sala"}>
                    <IconButton
                      key={item}
                      disabled={
                        !!disableActions && disableActions(attendance, "create")
                      }
                      onClick={() => {
                        content.onClickCreateMeet(attendance);
                      }}
                    >
                      <VideoCall
                        sx={{
                          color:
                            !!disableActions &&
                            disableActions(attendance, "create")
                              ? "disabled"
                              : "#FFF",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                );
              case "meet":
                return (
                  <Tooltip title={"Entrar na Sala"}>
                    <IconButton
                      key={item}
                      disabled={
                        !!disableActions && disableActions(attendance, "meet")
                      }
                      onClick={() => {
                        content.onClickJoinMeet(attendance);
                      }}
                    >
                      <Videocam
                        sx={{
                          color:
                            !!disableActions &&
                            disableActions(attendance, "meet")
                              ? "disabled"
                              : "#FFF",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                );
              case "finish":
                return (
                  <Tooltip title="Finalizar atendimento">
                    <IconButton
                      key={item}
                      color="success"
                      disabled={
                        !!disableActions && disableActions(attendance, "finish")
                      }
                      onClick={() => {
                        content.onClickFinish(attendance);
                      }}
                    >
                      <Check />
                    </IconButton>
                  </Tooltip>
                );
            }
          })}
        </Box>
      )}

      {content?.careState?.data?.tipo === "HOME CARE" && (
        <Box sx={{ display: "flex" }}>
          <BoxIcon onClick={handleClickOpen} sx={{ paddingTop: "4px" }}>
            <QRCodeIcon fill={theme.palette.common.black} />
            <Typography
              sx={{ cursor: "pointer" }}
              fontWeight={700}
              fontSize="0.75rem"
              color={theme.palette.secondary.main}
            >
              QR CODE
            </Typography>
          </BoxIcon>
        </Box>
      )}
      {content.telemedicine && (
        <Box>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              content.onClickTelemedicine();
            }}
          >
            Entrar na Sala
          </Button>
        </Box>
      )}

      {/* <DialogQrCode
        tittle={{ card: "Qr Code", info: ["Qr code"] }}
        content={content}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      /> */}
    </Container>
  );
}
