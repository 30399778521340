import React, { useEffect } from "react";
import DialogMedium from "../../../components/DialogMedium";
import Table from "../../../components/Table";
import ViewedDocumentHistory from "../viewedDocumentHistory";
import PaginationComponent from "../../../components/Pagination";
import { useDispatch } from "react-redux";
import {
  loadRequest,
} from "../../../store/ducks/integrationDocuments/actions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { RequestParamsDataSync } from "../../../store/ducks/integrationDocuments/sagas";

export default function ImageExams({ currentAttendance }: any) {
  const [openHistory, setOpenHistory] = React.useState(false);
  const [page, setPage] = React.useState<number | string>(1);
  const [limit, setLimit] = React.useState<number | string>(5);
  const dispatch = useDispatch();

  const integrationDocumentsState = useSelector(
    (state: ApplicationState) => state.integrationDocuments
  );

  const payload: RequestParamsDataSync = {
    data_sync_type: "IMAGE_EXAM",
    external_patient_id: currentAttendance?.id_patient,
    page,
    limit,
  };

  useEffect(() => {
    dispatch(
      loadRequest({
        ...payload,
        initial_date: "2000-01-01",
        end_date: "2030-01-01",
      })
    );
  }, [currentAttendance, page, limit]);

  return (
    <>
      <DialogMedium
        title="Histórico de Visualizações"
        open={openHistory}
        maxWidth="lg"
        handleClose={() => setOpenHistory(false)}
      >
        <ViewedDocumentHistory currentAttendance={currentAttendance} payload={payload} />
      </DialogMedium>
      <Table
        tableCells={[
          { name: "N° da Solicitação", align: "left" },
          { name: "Exame", align: "left" },
          { name: "Empresa", align: "left" },
          { name: "Data/Hora de Realização", align: "left" },
          { name: "Ações", align: "left" },
        ]}
        actions
        actionsTypes={["history", "image"]}
        actionHandleHistory={() => {
          setOpenHistory(true);
        }}
        actionHandleImage={(row) =>
          dispatch(
            loadRequest({
              ...payload,
              end_date: "2030-01-01",
              initial_date: "2000-01-01",
              data_sync_id: row?.data_sync_id,
            })
          )
        }
        integratedDocumentState={integrationDocumentsState}
      >
        {""}
      </Table>
      <PaginationComponent
        page={String(page)}
        rowsPerPage={String(limit)}
        totalRows={Number(integrationDocumentsState.data.total || 0)}
        handleFirstPage={() => setPage("1")}
        handleLastPage={() =>
          dispatch(
            loadRequest({
              page: Math.ceil(
                +integrationDocumentsState.data.total / +limit
              ).toString(),
              limit: limit.toString(),
              external_patient_id: currentAttendance?.id_patient,
              data_sync_type: "IMAGE_EXAM",
              initial_date: "2021-01-01",
              end_date: "2000-01-01",
            })
          )
        }
        handleNextPage={() => setPage(Number(page) + 1)}
        handlePreviosPage={() => setPage(Number(page) - 1)}
        handleChangeRowsPerPage={(event) => setLimit(event.target.value)}
      />
    </>
  );
}
