import styled from "styled-components";
import Menu from "@mui/material/Menu";
import { styled as styledMui } from "@mui/material/styles";
import { IconButton, IconButtonProps } from "@mui/material";

export const Th = styled.th`
  position: relative;
`;

export const MenuFilter = styled(Menu)`
  cursor: pointer;
  svg,
  path {
    cursor: pointer;
  }
  ul {
    padding: 0;
  }
  p {
    padding: 10px 14px;
    color: #666666;
    font-size: 12px;
    outline: none;
  }

  > div {
    /* top: 142px !important;
    left: 1195px !important; */
  }

  ul > li {
    /* padding: 0px !important; */
  }
`;

export const StyledIconButton = styledMui(IconButton)<IconButtonProps>(({ theme }) => ({
  alignItems: "center",
  textAlign: "center",
  cursor: "pointer",
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
  "& svg, path": { cursor: "pointer" },
  "&:disabled": {color: theme.palette.shadowColor.main}
}));
