/**
 * Action types
 */
export enum OpinioTypes {
  LOAD_REQUEST = "@opinion/LOAD_REQUEST",
  LOAD_SUCCESS = "@opinion/LOAD_SUCCESS",
  LOAD_FAILURE = "@opinion/LOAD_FAILURE",

  LOAD_DATA_REQUEST = "@opinions/LOAD_DATA_REQUEST",
  LOAD_DATA_SUCCESS = "@opinions/LOAD_DATA_SUCCESS",
  LOAD_DATA_FAILURE = "@opinions/LOAD_DATA_FAILURE",

  CREATE_ANSWER_REQUEST = "@opinions/CREATE_ANSWER_REQUEST",
  CREATE_ANSWER_SUCCESS = "@opinions/CREATE_ANSWER_SUCCESS",
  CREATE_ANSWER_FAILURE = "@opinions/CREATE_ANSWER_FAILURE",

  CLEAN = "@opinion/CLEAN",
}

/**
 * Data types
 */
interface rows {
  name: string;
  value: string | { name: string }
}

export interface opinions{
  _id: string;
  active:boolean;
  company_id: string;
  createdAt: string;
  external_attendance_id: string;
  external_requester_id: string;
  external_sector_name: string;
  external_specialty_id: string;
  external_specialty_name: string;
  priority_id: string;
  reason: string;
  requester_id : {
    _id: string,
    name: string,
  }
  started_at: string;
  updatedAt: string;
  status: {
    color: '#777777',
    label: ''
  }
}
export interface OpinionInterface {
  name: string;
  external_attendance_id: string;
  sector: string;
  rows: rows[];
  opinions: opinions[];
}

export interface OpinionInterfaceList {
  id: number;
  patient_name: string;
  birthdate: string;
  status: string;
  specialities?: string;
}

export interface OpinionList {
  data: OpinionInterfaceList[];
  limit: string;
  page: string;
  total: number;
  search?: string;
  status?: string;
}

export interface AnsewrOpinion {
  opinion_id: string;
  answered_by: string;
  external_answered_by: string;
  answer: string;
}

export interface CancelAnsewrOpinion {
  answer_id: string;
  opinion_id: string;
  answered_by: string;
  external_answered_by: string;
  answer: string;
}

/**
 * State type
 */
export interface OpinionState {
  data: OpinionInterface;
  list: OpinionList;
  loading: boolean;
  success: boolean;
}

/**
 * State type
 */
export type LoadRequestParams = Partial<Omit<OpinionList, "data">>;
