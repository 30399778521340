import React from "react";
interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}
export default function IconAllergic(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "33.57"}
      height={height ? height : "50"}
      viewBox="0 0 33.57 50"
    >
      <defs></defs>
      <path
        fill={fill}
        d="M23.3,30.775a.8.8,0,0,1,.076-.8L24.9,27.841a23.862,23.862,0,0,0,3.537-7.334,6.835,6.835,0,0,1,1.74-2.953.9.9,0,0,0-.636-1.533,3.731,3.731,0,0,0-2.225.742,8.113,8.113,0,0,0-2.669,3.48l-.417,1.042a1.82,1.82,0,0,1-3.5-.475l-.7-6.3a.711.711,0,0,1-.005-.088V4.005a.8.8,0,1,0-1.6,0v9.52a1.7,1.7,0,0,1-3.345.388L12.288,2.051A.581.581,0,0,0,11.72,1.6a1.33,1.33,0,0,0-1.256,1.667l2.954,10.342a5.159,5.159,0,0,1,.2,1.42,1.791,1.791,0,0,1-3.394.8L5.3,5.985a.684.684,0,0,0-1.3.307,2.837,2.837,0,0,0,.339,1.345l4.891,9.081a3.165,3.165,0,0,1,.377,1.5,1.809,1.809,0,0,1-3.391.88L2.936,13.185A.712.712,0,0,0,1.6,13.53a2.415,2.415,0,0,0,.344,1.242l2.548,4.246a44.461,44.461,0,0,1,3.157,6.362,33.755,33.755,0,0,0,4.857,8.385l1.37.456a4.364,4.364,0,0,0,3.8-.51l1.1-.733.889,1.333-1.1.733a5.965,5.965,0,0,1-5.2.7l-.284-.095L16,49.5l-1.568.33L11.271,34.795a35.4,35.4,0,0,1-5.106-8.822A42.974,42.974,0,0,0,3.121,19.84L.572,15.594A4.02,4.02,0,0,1,0,13.529,2.314,2.314,0,0,1,4.337,12.4l3.284,5.912a.207.207,0,0,0,.389-.1,1.555,1.555,0,0,0-.186-.738L2.934,8.4A4.44,4.44,0,0,1,2.4,6.292,2.286,2.286,0,0,1,6.734,5.268l4.923,9.847a.189.189,0,0,0,.358-.085,3.569,3.569,0,0,0-.137-.981L8.924,3.71A2.91,2.91,0,0,1,11.72,0a2.177,2.177,0,0,1,2.128,1.682l2.79,11.864a.1.1,0,0,0,.091.072.093.093,0,0,0,.093-.093V4.005a2.4,2.4,0,1,1,4.806,0V14.375l.7,6.257a.218.218,0,0,0,.419.056l.417-1.042a9.715,9.715,0,0,1,3.192-4.165,5.351,5.351,0,0,1,3.187-1.062,2.5,2.5,0,0,1,1.768,4.267,5.237,5.237,0,0,0-1.33,2.26,25.459,25.459,0,0,1-3.773,7.825l-1.254,1.756,8.618,18.8L32.114,50ZM20.026,47.261v-1.6h1.6v1.6Zm-1.6-5.607a1.6,1.6,0,1,1,1.6,1.6A1.6,1.6,0,0,1,18.424,41.654Zm2.4-4v-1.6h1.6v1.6Zm-8.812-9.613a1.6,1.6,0,1,1,1.6,1.6A1.6,1.6,0,0,1,12.016,28.036Zm6.408.8v-1.6h1.6v1.6Zm-2.4-7.209v-1.6h1.6v1.6Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
