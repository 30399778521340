import { Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ProfessionalIcon from "../../../components/Icons/Professional";
import TextField from "../../../components/FormFields/TextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AutocompleteField from "../../../components/FormFields/AutocompleteField";
import { ufOptions } from "../../../utils/optionsToFields";
import { useDispatch } from "react-redux";
import { loadSelectSpecialityRequest as loadSpecialities } from "../../../store/ducks/opinions/actions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
import {
  loadProfessionsRequest,
  loadUpdateCompanies,
  loadUpdateCompaniesLinks,
} from "../../../store/ducks/users/actions";
import PaginatedAutocompleteField from "../../../components/FormFields/PaginatedAutocompleteField";
import { apiSollar } from "../../../services/axios";
import { toast } from "react-toastify";
import LOCALSTORAGE from "../../../helpers/constants/localStorage";

async function getCompanies(params?: any) {
  try {
    const response = await apiSollar.get("/companies/list", {
      params,
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
}

export default function UserClientAccessAuthorization() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const requestOpinionState = useSelector(
    (state: ApplicationState) => state.opinion
  );
  const userLogged = localStorage.getItem(LOCALSTORAGE.USER_ID);
  const userState = useSelector((state: ApplicationState) => state.users);

  const initialValues = {
    _id: "",
    user_external_id: null,
    username: "",
    main_specialty: null,
    specialties: [],
    function: null,
    council: "",
    council_state: null,
    council_number: "",
    company: null,
  };

  const validationSchema = Yup.object().shape({
    user_external_id: Yup.string().when("company", {
      is: (value) => !value,
      then: Yup.string().required("Campo obrigatório"),
      otherwise: Yup.string().nullable(),
    }),
    username: Yup.string().when("company", {
      is: (value) => !value,
      then: Yup.string().required("Campo obrigatório"),
      otherwise: Yup.string().nullable(),
    }),
    main_specialty: Yup.object().when("company", {
      is: (value) => !value,
      then: Yup.object().required("Campo obrigatório").nullable(),
      otherwise: Yup.object().nullable(),
    }),
    specialties: Yup.array().when("company", {
      is: (value) => !value,
      then: Yup.array().required("Campo obrigatório").nullable(),
      otherwise: Yup.array().nullable(),
    }),
    function: Yup.object().when("company", {
      is: (value) => !value,
      then: Yup.object().required("Campo obrigatório").nullable(),
      otherwise: Yup.object().nullable(),
    }),
    council: Yup.string().when("company", {
      is: (value) => !value,
      then: Yup.string().required("Campo obrigatório").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    council_state: Yup.object().when("company", {
      is: (value) => !value,
      then: Yup.object().required("Campo obrigatório").nullable(),
      otherwise: Yup.object().nullable(),
    }),
    council_number: Yup.string().when("company", {
      is: (value) => !value,
      then: Yup.string().required("Campo obrigatório").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    company: Yup.object().nullable(),
  });

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const mainSpecialty = watch("main_specialty");

  const otherSpecialties = requestOpinionState.specialities.filter(
    (specialty: any) => specialty.id !== mainSpecialty?.id
  );

  useEffect(() => {
    dispatch(loadSpecialities("form"));
    dispatch(loadProfessionsRequest());
  }, []);

  useEffect(() => {
    if (userState.success) {
      navigate("/user");
    }
  }, [userState.success]);

  const onSubmit = (data) => {
    data._id = params.id;
    data.function = data.function?.name;
    data.main_specialty = data.main_specialty?.name;
    data.council_state = data.council_state?.describe;
    data.specialties = data.specialties
      .map((specialty) => specialty.name)
      .join(", ");
    if (userState?.data?.access_granted && !!data.company._id) {
      dispatch(
        loadUpdateCompanies({ company_id: data.company._id, _id: data._id })
      );
      reset();
      return;
    }

    dispatch(loadUpdateCompaniesLinks(data));
    reset();
  };

  return (
    <Grid
      container
      spacing={2}
      px={3.2}
      py={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12} display="flex" alignItems="center" gap={1} mb={1}>
        <ProfessionalIcon fill={theme.palette.primary.main} />
        <Typography variant="body1" fontWeight={600}>
          {userState.data?.access_granted
            ? "Solicitar acesso a nova empresa"
            : "Autorização de acesso"}
        </Typography>
      </Grid>
      {userState.data?.access_granted ? (
        <>
          <Grid item xs={12}>
            <PaginatedAutocompleteField
              control={control}
              label="Escolha a empresa que deseja acessar"
              disabled={params.id !== userLogged}
              name="company"
              queryKey="companies"
              filterKey="name"
              service={async (params) => getCompanies(params)}
              AutocompleteProps={{
                noOptionsText: "Sem resultados. Tente outra busca",
              }}
              optionLabelKey="name"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item md={2} xs={6}>
            <TextField
              control={control}
              name="user_external_id"
              label="Cód. Prestador"
              required
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              control={control}
              name="username"
              label="Nome de usuário"
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AutocompleteField
              control={control}
              name="main_specialty"
              label="Especialidade Principal"
              options={requestOpinionState.specialities}
              optionLabelKey={"name"}
              customOnChange={() => setValue("specialties", [])}
              required
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <AutocompleteField
              control={control}
              name="specialties"
              label="Outras Especialidades"
              options={otherSpecialties}
              optionLabelKey={"name"}
              multiple
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <AutocompleteField
              control={control}
              name="function"
              label="Função"
              options={userState.data?.professions}
              optionLabelKey={"name"}
              required
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              control={control}
              name="council"
              label="Conselho"
              type="text"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <AutocompleteField
              control={control}
              name="council_state"
              label="UF Conselho"
              options={ufOptions}
              optionLabelKey={"describe"}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              control={control}
              name="council_number"
              label="Código Conselho"
              type="number"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          type="submit"
          disabled={params.id !== userLogged && userState.data?.access_granted}
        >
          {userState.data?.access_granted
            ? "Solicitar acesso"
            : "Liberar acesso"}
        </Button>
      </Grid>
    </Grid>
  );
}
