import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}

function SvgComponent(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      width={width ? width : 16.034}
      height={height ? height : 16.251}
      viewBox="0 0 16.034 16.251"
    >
      <g transform="translate(-175 -349.12)">
        <g transform="translate(175 349.12)">
          <path
            d="M6.934,15.467V.534A.534.534,0,0,0,6.4,0H.534A.534.534,0,0,0,0,.534V15.467A.534.534,0,0,0,.534,16H6.4a.534.534,0,0,0,.533-.533ZM.534.534H6.4v.8H2.667a.267.267,0,1,0,0,.533H6.4V2.4H4.534a.267.267,0,1,0,0,.533H6.4v.533H2.667a.267.267,0,1,0,0,.533H6.4v.533H4.534a.267.267,0,1,0,0,.533H6.4V5.6H2.667a.267.267,0,1,0,0,.533H6.4v.533H4.534a.267.267,0,1,0,0,.533H6.4v.533H2.667a.267.267,0,0,0,0,.533H6.4V8.8H4.534a.267.267,0,1,0,0,.533H6.4v.533H2.667a.267.267,0,1,0,0,.533H6.4v.533H4.534a.267.267,0,1,0,0,.533H6.4V12H2.667a.267.267,0,1,0,0,.533H6.4v.533H4.534a.267.267,0,1,0,0,.533H6.4v.533H2.667a.267.267,0,1,0,0,.533H6.4v.8H.534Zm0,0"
            transform="translate(0 -0.001)"
            fill={fill}
          />
        </g>
        <g transform="translate(-125.451 -135.88)">
          <path
            d="M30.956,4.854A2.1,2.1,0,1,0,27.8,2.541h0l-.071.67h0a2.1,2.1,0,0,0,.95,1.642,3.674,3.674,0,0,0-2.528,3.486v1.835a1.312,1.312,0,0,0,1.311,1.311h.262v4.587a1.175,1.175,0,0,0,2.1.732,1.175,1.175,0,0,0,2.1-.732V11.484h.262a1.312,1.312,0,0,0,1.311-1.311V8.339A3.674,3.674,0,0,0,30.956,4.854ZM28.242,3.1A1.573,1.573,0,1,1,29.815,4.67,1.574,1.574,0,0,1,28.242,3.1Zm4.718,7.077a.787.787,0,0,1-.786.786h-.262V7.029h-.524v9.043a.655.655,0,0,1-1.311,0V9.65h-.524v6.422a.655.655,0,1,1-1.311,0V7.029h-.524V10.96h-.262a.787.787,0,0,1-.786-.786V8.339a3.145,3.145,0,1,1,6.291,0Z"
            transform="translate(283 484)"
            fill={fill}
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
