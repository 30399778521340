import { Reducer } from "redux";
import { DataSyncState, DataSyncTypes } from "./types";

export const INITIAL_STATE: DataSyncState = {
  data: {
    items: [],
    limit: "10",
    page: "1",
    total: 0,
    url: null,
    logs: {
      items: [],
      limit: "10",
      page: "1",
      total: 0,
    },
  },
  loading: false,
  success: false,
  error: false,
};

const reducer: Reducer<DataSyncState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DataSyncTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case DataSyncTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload, items: action.payload.data },
        loading: false,
        error: false,
        success: true,
      };
    case DataSyncTypes.LOAD_REQUEST_EXAM_URL:
      return { ...state, loading: true };
    case DataSyncTypes.LOAD_REQUEST_LOGS:
      return { ...state, loading: true };
    case DataSyncTypes.LOAD_SUCCESS_EXAM_URL:
      return {
        ...state,
        data: { ...state.data, url: action.payload?.url },
        loading: false,
        error: false,
        success: true,
      };
    case DataSyncTypes.LOAD_SUCCESS_LOGS:
      return {
        ...state,
        data: { ...state.data, logs: {items: action.payload?.data, ...action.payload} },
        loading: false,
        error: false,
        success: true,
      };
    case DataSyncTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        data: INITIAL_STATE.data,
      };
    default:
      return state;
  }
};

export default reducer;
