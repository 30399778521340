import { action } from "typesafe-actions";
import {
  OpinioTypes,
  OpinionInterfaceList,
  LoadRequestParams,
  OpinionInterface,
  AnsewrOpinion
} from "./types";

/** lista de solicitação do parecer */
export const loadRequest = (params: LoadRequestParams = {}) =>
  action(OpinioTypes.LOAD_REQUEST, { params });

export const loadSuccess = (data: OpinionInterfaceList) =>
  action(OpinioTypes.LOAD_SUCCESS, { data });

export const loadFailure = () => action(OpinioTypes.LOAD_FAILURE);

/** dados da solicitação do parecer */
export const loadOpinionRequest = (params: {id: string}) =>
  action(OpinioTypes.LOAD_DATA_REQUEST, { params });

export const loadOpinionSuccess = (data: OpinionInterface) =>
  action(OpinioTypes.LOAD_DATA_SUCCESS, { data });

export const loadOpinionFailure = () => action(OpinioTypes.LOAD_DATA_FAILURE);

/** criação da resposta do parecer */
export const createAnswerRequest = (data: AnsewrOpinion) =>
  action(OpinioTypes.CREATE_ANSWER_REQUEST, { ...data });

export const createAnswerSuccess = (data: AnsewrOpinion) =>
  action(OpinioTypes.CREATE_ANSWER_SUCCESS, { ...data });

export const createAnswerFailure = () => action(OpinioTypes.CREATE_ANSWER_FAILURE);


export const cleanAction = () => action(OpinioTypes.CLEAN);
