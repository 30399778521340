import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { checkViewPermission } from "../../../utils/permissions";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../../store";
import { Container, Tab } from "@mui/material";
import TabTittle from "../../../components/Text/TabTittle";
import PaginationComponent from "../../../components/Pagination";
import NoPermission from "../../../components/Erros/NoPermission";
import SESSIONSTORAGE from "../../../helpers/constants/sessionStorage";
import Table from "../../../components/Table";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import {
  loadRequest,
} from "../../../store/ducks/telemedicineAttendances/actions";
import {
  ITelemedicineAttendancesRequestParams,
} from "../../../store/ducks/telemedicineAttendances/types";
import SignaturePassword from "../../../components/SignaturePassword";

export default function TelemedicineList() {
  const integration = sessionStorage.getItem(SESSIONSTORAGE.INTEGRATION);
  const dispatch = useDispatch();
  const [valueTab, setValueTab] = useState("0");
  const [attendanceType, setAttendanceType] = useState("U");
  const [page, setPage] = useState<string | number>("1");
  const [limit, setLimit] = useState<string | number>("10");
  const [refetch, setRefetch] = useState(false);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "0") {
      setAttendanceType("U");
    } else {
      setAttendanceType("I");
    }
    setValueTab(newValue);
  };

  const listPayload: ITelemedicineAttendancesRequestParams = {
    attendance_type: attendanceType,
    limit,
    page,
  };

  useEffect(() => {
    dispatch(loadRequest(listPayload));
    setRefetch(false);
  }, [attendanceType, page, limit, refetch]);

  const telemedicineAttendancesState = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances
  );

  const rightsOfLayoutState = useSelector(
    (state: ApplicationState) => state.layout.data.rights
  );

  const digitalSignatureState = useSelector(
    (state: ApplicationState) => state.digitalSignature
  );

  return (
    <Sidebar>
      {checkViewPermission(
        "telemedicine",
        JSON.stringify(rightsOfLayoutState)
      ) ? (
        <Container>
          <SignaturePassword open={digitalSignatureState.isOpen} />
          <TabTittle tittle="Telemedicina" />
          {integration ? (
            <>
              <TabContext value={valueTab}>
                <TabList
                  onChange={handleChangeTab}
                  scrollButtons="auto"
                  sx={{ marginLeft: 3 }}
                >
                  <Tab label={"Urgência"} id={`tab-${valueTab}`} value="0" />
                  <Tab label={"Internação"} id={`tab-${valueTab}`} value="1" />
                </TabList>
                <TabPanel value={valueTab}>
                  <Table
                    tableCells={[
                      { name: "Atendimento", align: "left" },
                      { name: "Paciente", align: "left" },
                      { name: "Data de Nascimento", align: "center" },
                      { name: "Médico Assistente", align: "left" },
                      { name: "Especialidade", align: "center" },
                      { name: "Data do Atendimento", align: "center" },
                      { name: "Status", align: "center" },
                    ]}
                    telemedicineAttendanceState={telemedicineAttendancesState}
                  >
                    {""}
                  </Table>
                </TabPanel>
              </TabContext>
            </>
          ) : (
            <>
              <Table
                tableCells={[
                  { name: "Atendimento", align: "left" },
                  { name: "Paciente", align: "left" },
                  { name: "Data de Nascimento", align: "center" },
                  { name: "Médico Assistente", align: "left" },
                  { name: "Especialidade", align: "center" },
                ]}
              >
                {""}
              </Table>
            </>
          )}
          <PaginationComponent
            page={String(page)}
            rowsPerPage={String(limit)}
            totalRows={Number(telemedicineAttendancesState.data.total || 0)}
            handleFirstPage={() => setPage("1")}
            handleLastPage={() =>
              dispatch(
                loadRequest({
                  page: Math.ceil(
                    +telemedicineAttendancesState.data.total / +limit
                  ).toString(),
                  limit: limit.toString(),
                  ...listPayload,
                })
              )
            }
            handleNextPage={() => setPage(Number(page) + 1)}
            handlePreviosPage={() => setPage(Number(page) - 1)}
            handleChangeRowsPerPage={(event) => setLimit(event.target.value)}
          />
        </Container>
      ) : (
        <NoPermission />
      )}
    </Sidebar>
  );
}
