import { action } from "typesafe-actions";
import {
  PanelTypes,
  IPanelParams,
  IPanelList,
  IOriginList
} from "./types";

export const loadRequest = (params: IPanelParams) =>
  action(PanelTypes.LOAD_REQUEST, params);

export const loadSuccess = (data: IPanelList[]) =>
  action(PanelTypes.LOAD_SUCCESS, data);
export const loadRequestOrigin = () =>
  action(PanelTypes.LOAD_REQUEST_ORIGIN);

export const loadSuccessOrigin = (data: IOriginList[]) =>
  action(PanelTypes.LOAD_SUCCESS_ORIGIN, data);

export const loadFailure = () => action(PanelTypes.LOAD_FAILURE);
