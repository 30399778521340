import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}

export default function checkMedIcon(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      id="icon_sollar_Checagens"
      data-name="icon sollar Checagens"
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "36.893"}
      height={height ? height : "50.201"}
      viewBox="0 0 36.893 50.201"
    >
      <g
        id="Grupo_11405"
        data-name="Grupo 11405"
        transform="translate(-1333.414 -139.094)"
      >
        <g
          id="Grupo_11400"
          data-name="Grupo 11400"
          transform="translate(-132.795 -9.609)"
        >
          <g id="Grupo_11410" data-name="Grupo 11410">
            <g id="Grupo_11411" data-name="Grupo 11411">
              <path
                id="Subtração_8"
                data-name="Subtração 8"
                d="M20279.182,19821.273h-16.973a4.349,4.349,0,0,1-4.416-4.264v-20.1a6.553,6.553,0,0,1,3.615-5.934c.35-.205.705-.4,1.01-.564l.027-.016c.438-.24.814-.447,1.1-.643.488-.338.57-.484.57-.758v-3.311a2.276,2.276,0,0,1-1.482-2.135v-6.7a2.249,2.249,0,0,1,2.246-2.246h15.83a2.249,2.249,0,0,1,2.246,2.246v6.7a2.265,2.265,0,0,1-1.523,2.135V19789c0,.27.119.418.611.758.279.191.648.4,1.078.631l.027.018c.324.174.672.365,1.023.574a6.544,6.544,0,0,1,3.621,5.934v10.525a8.519,8.519,0,0,0-1.828-.309v-9.34h-26.342v17.7h17.373v.1a8.59,8.59,0,0,0,.176,1.727h-17.508a2.488,2.488,0,0,0,2.547,2.129h15.711a8.413,8.413,0,0,0,1.26,1.828Zm-13.24-35.477v3.2a2.829,2.829,0,0,1-1.369,2.246,14.407,14.407,0,0,1-1.348.789l-.061.033c-.277.152-.566.307-.838.471a4.5,4.5,0,0,0-2.629,3.422h26.189a4.564,4.564,0,0,0-2.664-3.422c-.248-.146-.5-.289-.748-.424l-.078-.045-.041-.021a15.637,15.637,0,0,1-1.344-.8,2.86,2.86,0,0,1-1.4-2.246v-3.2h-13.666Zm-1.064-9.359a.393.393,0,0,0-.422.418v6.7a.4.4,0,0,0,.422.418h15.83a.4.4,0,0,0,.422-.418v-6.7a.393.393,0,0,0-.422-.418Zm9.32,35.545h-2.816v-3.916h-3.916v-2.816h3.916v-3.922h2.816v3.922h3.922v2.816h-3.922v3.916Z"
                transform="translate(-18791.584 -19625.906)"
                fill={fill}
              />
              <g
                id="Grupo_11406"
                data-name="Grupo 11406"
                transform="translate(20049.115 19089.078)"
              >
                <g
                  id="Grupo_11397"
                  data-name="Grupo 11397"
                  transform="translate(-18564.459 -18908.619)"
                >
                  <path
                    id="Elipse_311"
                    data-name="Elipse 311"
                    d="M9.223,1.5a7.723,7.723,0,1,0,7.723,7.723A7.731,7.731,0,0,0,9.223,1.5m0-1.5A9.223,9.223,0,1,1,0,9.223,9.223,9.223,0,0,1,9.223,0Z"
                    transform="translate(0 0)"
                    fill={fill}
                  />
                </g>
                <path
                  id="Caminho_9236"
                  data-name="Caminho 9236"
                  d="M.239.154a.663.663,0,0,0-.085.933h0S2.666,4.106,3.464,5.06a.659.659,0,0,0,.442.238.55.55,0,0,0,.07,0l4.156-.689a.663.663,0,1,0,0-1.325l-3.846.689L1.172.238A.663.663,0,0,0,.239.154Z"
                  transform="matrix(0.819, 0.574, -0.574, 0.819, -18555.959, -18904.879)"
                  fill={fill}
                />
                <path
                  id="Caminho_9236_-_Contorno"
                  data-name="Caminho 9236 - Contorno"
                  d="M.662-.15h0a.811.811,0,0,1,.625.292l3.059,3.67,3.786-.676a.813.813,0,0,1,.013,1.625l-4.169.689a.7.7,0,0,1-.089,0,.814.814,0,0,1-.538-.29C2.619,4.283.426,1.648.083,1.237H0v-.1A.813.813,0,0,1,.143.038.814.814,0,0,1,.662-.15ZM4.227,4.139,1.057.334A.512.512,0,0,0,.662.15h0a.513.513,0,0,0-.4.834L.269.991c.025.03,2.521,3.03,3.31,3.973a.51.51,0,0,0,.346.185l.039,0,4.168-.689a.513.513,0,0,0,.012-1.025Z"
                  transform="matrix(0.819, 0.574, -0.574, 0.819, -18555.959, -18904.879)"
                  fill={fill}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
