import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { loadSuccess, loadFailure, loadSuccessOrigin } from "./actions";
import { apiSollar } from "../../../services/axios";
import { IOriginList, PanelTypes } from "./types";

interface IPayloadPanel {
  type: typeof PanelTypes.LOAD_REQUEST;
  payload: {
    limit: number;
    page: number;
    origin_id: number;
    origin_name: string;
  };
}

export function* getPanel({ payload }: IPayloadPanel) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `/panel/getMedications?limit=${payload.limit ? payload.limit : 10}&page=${
        payload.page || 1
      }&origin_id=${payload.origin_id}&origin_name=${payload.origin_name}`
    );

    yield put(loadSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao buscar informações do Painel");
    yield put(loadFailure());
  }
}

export function* getOrigins() {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `/origin`
    );

    yield put(loadSuccessOrigin(response.data));
  } catch (error) {
    toast.error("Erro ao buscar as Origens");
    yield put(loadFailure());
  }
}
