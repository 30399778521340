import axios, { AxiosResponse } from "axios";
import {
  ITelemedicineAttendanceByIdParams,
  ITelemedicineAttendanceByIdPayload,
  ITelemedicineAttendancesCreateMeetPayload,
  ITelemedicineAttendancesGetBiomeasuresPayload,
  ITelemedicineAttendancesRequestPayload,
  ITelemedicineAttendancesUpdateStatusPayload,
  ITelemedicineCancelDocumentPayload,
  ITelemedicineCreateDocumentPayload,
  ITelemedicineGetDocumentsPayload,
} from "./types";
import { call, put } from "redux-saga/effects";
import {
  loadFailure,
  loadSuccess,
  loadSuccessAttendanceById,
  loadSuccessCancelDocument,
  loadSuccessCreateDocument,
  loadSuccessCreateMeet,
  loadSuccessGetBiomeasuresByAttendance,
  loadSuccessGetDocumentsByType,
  loadSuccessUpdateStatus,
} from "./actions";
import { apiSollar } from "../../../services/axios";
import { toast } from "react-toastify";

const path =
  "/telemedicine/attendances";

const path2 = "/telemedicine/attendance"

const pathDocs = "/telemedicine/docs"


export function* getPatientsForTelemedicine({
  payload,
}: ITelemedicineAttendancesRequestPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `${path}/show?limit=${
        payload.limit ? payload.limit : 10
      }&page=${payload.page || 1}&attendance_type=${
        payload.attendance_type
      }`,
    );

    yield put(loadSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao buscar informações da lista de Telemedicina");
    yield put(loadFailure());
  }
}

export function* getAttendanceMeetUrl({
  payload,
}: ITelemedicineAttendancesCreateMeetPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.post as any,
      `${path2}/generateUrl`,
      payload
    );

    yield put(loadSuccessCreateMeet(response.data));
  } catch (error) {
    toast.error("Erro ao gerar link da sala para o atendimento");
    yield put(loadFailure());
  }
}

export function* updateAttendance({
  payload,
}: ITelemedicineAttendancesUpdateStatusPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.post as any,
      `${path2}/update?_id=${
        payload._id
      }`,
      {...payload}
    );

    yield put(loadSuccessUpdateStatus(response.data));
  } catch (error) {
    toast.error("Erro ao atualizar status do atendimento");
    yield put(loadFailure());
  }
}

export function* getTelemedicineDocumentsByType({
  payload,
}: ITelemedicineGetDocumentsPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `${pathDocs}/show?telemedicine_attendance_id=${payload?.telemedicine_attendance_id}&document_type=${payload?.document_type}`,
    );
    response.data.document_type = payload.document_type
    yield put(loadSuccessGetDocumentsByType(response.data));
  } catch (error) {
    toast.error("Erro ao buscar documentos do atendimento");
    yield put(loadFailure());
  }
}

export function* createTelemedicineDocument({
  payload,
}: ITelemedicineCreateDocumentPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.post as any,
      `${pathDocs}/store`,
      payload
    );

    yield put(loadSuccessCreateDocument());
  } catch (error) {
    toast.error("Erro ao criar documento");
    yield put(loadFailure());
  }
}

export function* cancelTelemedicineDocument({
  payload,
}: ITelemedicineCancelDocumentPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.post as any,
      `${pathDocs}/cancel?_id=${payload._id}`,
      {canceled_reason: payload.canceled_reason}
    );

    yield put(loadSuccessCancelDocument());
  } catch (error) {
    // toast.error("Erro ao cancelar documento");
    yield put(loadFailure());
  }
}

export function* getBiomeasuresByAttendance({
  payload,
}: ITelemedicineAttendancesGetBiomeasuresPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `${path2}/biomeasures?attendance_id=${payload?.attendance_id}`,
    );

    yield put(loadSuccessGetBiomeasuresByAttendance(response.data));
  } catch (error) {
    toast.error("Erro ao buscar biomedidas do paciente");
    yield put(loadFailure());
  }
}

export function* getAttendanceById({
  payload,
}: ITelemedicineAttendanceByIdPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `${path2}/show/${payload?.attendance_id}`,
    );

    yield put(loadSuccessAttendanceById(response.data));
  } catch (error) {
    toast.error("Erro ao buscar biomedidas do paciente");
    yield put(loadFailure());
  }
}
