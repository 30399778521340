import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../theme/theme";

interface IDoseMeter {
  max: number,
  min: number
}

export default function DoseMeter({ max, min }: IDoseMeter) {
  const widthPercent = (min / max) * 100;
  return (
    <Box display={"flex"} alignItems={"center"} gap={0.5} minWidth={90} width={"100%"}>
      <Box
        sx={{
          height: 7,
          width: "100%",
          backgroundColor: theme.palette.shadowColor.main,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            height: 7,
            width: `${widthPercent}%`,
            backgroundColor: theme.palette.success.main,
            borderRadius: 4,
          }}
        />
      </Box>
      <Typography fontSize={10}>{min}/{max}</Typography>
    </Box>
  );
}
