import { Reducer } from "redux";
import { DigitalSignatureState, DigitalSignatureTypes } from "./types";

export const INITIAL_STATE = {
  isOpen: false,
  data: null,
  loading: false,
  success: false,
  error: false,
};

const reducer: Reducer<DigitalSignatureState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case DigitalSignatureTypes.OPEN_POPUP:
      return { ...state, isOpen: true };
    case DigitalSignatureTypes.CLOSE_POPUP:
      return { ...state, isOpen: false };
    case DigitalSignatureTypes.LOAD_REQUEST:
      return { ...state, loading: true, success: false };
    case DigitalSignatureTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isOpen: false,
        loading: false,
        success: true,
        error: false,
      };
    case DigitalSignatureTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, success: false };
    default:
      return state;
  }
};

export default reducer;
