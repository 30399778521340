import { action } from "typesafe-actions";
import {
  RevaluationTypes,
  IRevaluationRequestParams,
  IRevaluationResponse,
  IRevaluationCreateAttendanceBody,
  IRevaluationUpdateStatusParams,
  IRevaluationGetExamsParams,
  IRevaluationGetExamsResponse,
} from "./types";

export const loadRequest = (params: IRevaluationRequestParams) =>
  action(RevaluationTypes.LOAD_REQUEST, params);

export const loadCreateAttendance = (params: IRevaluationCreateAttendanceBody) =>
  action(RevaluationTypes.LOAD_REQUEST_CREATE_ATTENDANCE, params);

export const loadRequestUpdateStatus = (params: IRevaluationUpdateStatusParams) =>
  action(RevaluationTypes.LOAD_REQUEST_UPDATE_STATUS, params);

export const loadRequestGetExams = (params: IRevaluationGetExamsParams) =>
  action(RevaluationTypes.LOAD_REQUEST_GET_EXAMS, params);

export const loadRequestGetAttendanceById = (param: {external_attendance_id: string | number}) =>
  action(RevaluationTypes.LOAD_REQUEST_GET_ATTENDANCE_BY_ID, param);

export const loadSuccess = (data: IRevaluationResponse[]) =>
  action(RevaluationTypes.LOAD_SUCCESS, data);

export const loadSuccessCreateAttendance = () =>
  action(RevaluationTypes.LOAD_SUCCESS_CREATE_ATTENDANCE);

export const loadSuccessUpdateStatus = () =>
  action(RevaluationTypes.LOAD_SUCCESS_UPDATE_STATUS);

export const loadSuccessGetExams = (data: IRevaluationGetExamsResponse[]) =>
  action(RevaluationTypes.LOAD_SUCCESS_GET_EXAMS, data);

export const loadSuccessGetAttendanceById = (data: any) =>
  action(RevaluationTypes.LOAD_SUCCESS_GET_ATTENDANCE_BY_ID, data);

export const loadFailure = () => action(RevaluationTypes.LOAD_FAILURE);
