import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ITabProps } from "./types";

function Tabs({
  tabs,
  labelIdentifier,
  content = true,
  orientation = "horizontal",
  variant,
  scrollButtons,
  handleClick,
  tabListSx
}: ITabProps) {
  const [tab, setTab] = React.useState("0");

  function handleChange(_, value: string) {
    setTab(value);
  }

  return (
    <TabContext value={tab}>
      <TabList
        variant={variant}
        scrollButtons={scrollButtons}
        orientation={orientation}
        onChange={handleChange}
        sx={{...tabListSx}}
      >
        {tabs?.map(({ onClick, icon, ...tab }, index) => {
          const label = labelIdentifier ? tab[labelIdentifier] : tab.label;

          return (
            <Tab
              key={`${label} - ${index}`}
              label={label}
              value={String(index)}
              icon={icon}
              onClick={() => {
                if (handleClick) handleClick(tab);
                else if (onClick) onClick(tab);
              }}
            />
          );
        })}
      </TabList>
      {content && tabs?.map((tab, index) => (
        <TabPanel
          key={`${tab} - ${index}`}
          value={String(index)}
          sx={{
            "&.MuiTabPanel-root": {
              p: 0,
              mt: 1
            },
          }}
        >
          {tab.content}
        </TabPanel>
      ))}
    </TabContext>
  );
}

export default React.memo(Tabs);
