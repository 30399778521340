'use client';

import React from 'react';
import { IconButton, InputAdornment, TextField as MUITextField, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { ITextField } from './types';
import { formatWithMask } from '../../../utils/mask';

export default function TextField({
  control,
  name,
  label,
  password,
  mask,
  leftIcon,
  rightIcon,
  required,
  disabled,
  customOnChange,
  onBlur,
  onChange,
  ...props
}: ITextField) {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  let icon: any = null;

  if (rightIcon) {
    icon = <InputAdornment position="end">{}</InputAdornment>;
  } else if (password) {
    icon = (
      <IconButton onClick={togglePassword}>
        {showPassword ? (
          <VisibilityRoundedIcon htmlColor={theme.palette.primary[200]} />
        ) : (
          <VisibilityOffRoundedIcon htmlColor={theme.palette.primary[200]} />
        )}
      </IconButton>
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <MUITextField
          {...field}
          type={!showPassword && password ? 'password' : 'text'}
          disabled={disabled}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          label={label}
          fullWidth
          variant="outlined"
          size="small"
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: leftIcon ? (
              <InputAdornment position="start">{leftIcon}</InputAdornment>
            ) : null,
            endAdornment: icon,
          }}
          onChange={(event) => {
            let newValue = event.target.value;

            if (mask) {
              newValue = formatWithMask({
                mask,
                text: newValue,
              }).masked;
            }

            if (onChange instanceof Function) {
              onChange(newValue);
            } else {
              field.onChange(newValue);
            }

            if (customOnChange instanceof Function) {
              customOnChange(newValue);
            }
          }}
          onBlur={(event) => {
            const newValue = event.target.value;

            if (onBlur instanceof Function) {
              onBlur(newValue);
            } else {
              field.onBlur();
            }
          }}
          {...props}
        />
      )}
    />
  );
}
