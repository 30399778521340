export const ufOptions = [
  { describe: "AC" },
  { describe: "AL" },
  { describe: "AP" },
  { describe: "AM" },
  { describe: "BA" },
  { describe: "CE" },
  { describe: "DF" },
  { describe: "ES" },
  { describe: "GO" },
  { describe: "MA" },
  { describe: "MT" },
  { describe: "MS" },
  { describe: "MG" },
  { describe: "PA" },
  { describe: "PB" },
  { describe: "PR" },
  { describe: "PE" },
  { describe: "PI" },
  { describe: "RJ" },
  { describe: "RN" },
  { describe: "RS" },
  { describe: "RO" },
  { describe: "RR" },
  { describe: "SC" },
  { describe: "SP" },
  { describe: "SE" },
  { describe: "TO" },
];