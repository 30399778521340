import { UserInterface } from "../users/types";

/**
 * action types
 */
export enum DataSyncTypes {
  LOAD_REQUEST = "@data-sync/LOAD_REQUEST",
  LOAD_SUCCESS = "@data-sync/LOAD_SUCCESS",
  LOAD_REQUEST_EXAM_URL = "@data-sync/LOAD_REQUEST_EXAM_URL",
  LOAD_SUCCESS_EXAM_URL = "@data-sync/LOAD_SUCCESS_EXAM_URL",
  LOAD_REQUEST_LOGS = "@data-sync/LOAD_REQUEST_LOGS",
  LOAD_SUCCESS_LOGS = "@data-sync/LOAD_SUCCESS_LOGS",
  LOAD_FAILURE = "@data-sync/LOAD_FAILURE",
}
/**
 * data types
 */
export interface DataSyncInterface {
  id: number;
  count: number;
  accessNumber: number;
  externalId: string;
  name: string;
  company: string;
  requestRxId: number;
  requestDate: string;
  requestHour: string;
  exam: string;
  describe: string;
  isPerformed: string;
  performedDate: string;
  data_sync_id: number;
  reference_date: string;
}

export interface DataSyncLogInterface {
  _id: string;
  visit_at: string;
  visit_by: UserInterface;
}

/**
 * state type
 */
export interface DataSyncState {
  data: {
    items?: DataSyncInterface[];
    limit?: string;
    page?: string;
    total?: number;
    url?: string;
    logs?: {
      items?: DataSyncLogInterface[];
      limit?: string;
      page?: string;
      total?: number;
    };
  };
  loading: boolean;
  error: boolean;
  success: boolean;
}

export type LoadRequestParams = Partial<Omit<any, "data">>;
