import React from "react";
interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}

export default function AdverseIcon(props: IPropsSvg) {
  const { fill, width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "22.271"}
      height={height ? height : "22.272"}
      viewBox="0 0 22.271 22.272"
    >
      <g
        id="Grupo_10693"
        data-name="Grupo 10693"
        transform="translate(-3451.142 -9014.135)"
      >
        <g id="Grupo_10667" data-name="Grupo 10667">
          <path
            id="Caminho_8906"
            data-name="Caminho 8906"
            d="M-1564.181-914.546h-.381a10.09,10.09,0,0,1-2.966,7.16,10.089,10.089,0,0,1-7.159,2.966,10.093,10.093,0,0,1-7.161-2.966,10.094,10.094,0,0,1-2.966-7.16,10.094,10.094,0,0,1,2.966-7.16,10.093,10.093,0,0,1,7.161-2.966,10.089,10.089,0,0,1,7.159,2.966,10.09,10.09,0,0,1,2.966,7.16h.76a10.885,10.885,0,0,0-10.885-10.886,10.886,10.886,0,0,0-10.886,10.886,10.886,10.886,0,0,0,10.886,10.886,10.885,10.885,0,0,0,10.885-10.886Z"
            transform="translate(5036.965 9939.816)"
            fill={fill}
            stroke={fill}
            stroke-width="0.5"
          />
          <path
            id="Caminho_8907"
            data-name="Caminho 8907"
            d="M-1560.073-887.105l2.279,2.278a.475.475,0,0,0,.672,0,.476.476,0,0,0,0-.672l-2.279-2.278a.475.475,0,0,0-.672,0,.475.475,0,0,0,0,.672"
            transform="translate(5016.14 9909.012)"
            fill={fill}
            stroke={fill}
            stroke-width="0.5"
          />
          <path
            id="Caminho_8908"
            data-name="Caminho 8908"
            d="M-1557.794-887.777l-2.279,2.278a.475.475,0,0,0,0,.672.475.475,0,0,0,.672,0l2.279-2.278a.476.476,0,0,0,0-.672.475.475,0,0,0-.672,0"
            transform="translate(5016.14 9909.012)"
            fill={fill}
            stroke={fill}
            stroke-width="0.5"
          />
          <path
            id="Caminho_8909"
            data-name="Caminho 8909"
            d="M-1507.135-887.105l2.279,2.278a.474.474,0,0,0,.671,0,.474.474,0,0,0,0-.672l-2.277-2.278a.476.476,0,0,0-.673,0,.474.474,0,0,0,0,.672"
            transform="translate(4972.672 9909.012)"
            fill={fill}
            stroke={fill}
            stroke-width="0.5"
          />
          <path
            id="Caminho_8910"
            data-name="Caminho 8910"
            d="M-1504.856-887.777l-2.279,2.278a.474.474,0,0,0,0,.672.476.476,0,0,0,.673,0l2.277-2.278a.474.474,0,0,0,0-.672.474.474,0,0,0-.671,0"
            transform="translate(4972.672 9909.012)"
            fill={fill}
            stroke={fill}
            stroke-width="0.5"
          />
          <path
            id="Caminho_8911"
            data-name="Caminho 8911"
            d="M-1549.85-851.323a.876.876,0,0,1-.331.465.982.982,0,0,1-.578.181.941.941,0,0,1-.58-.2.952.952,0,0,1-.336-.5l-.367.1.127.358h0a.382.382,0,0,0,.231-.486,1.723,1.723,0,0,0-.617-.816,1.7,1.7,0,0,0-1-.32,1.7,1.7,0,0,0-1.027.343,1.715,1.715,0,0,0-.61.87.889.889,0,0,1-.333.465.976.976,0,0,1-.576.181.941.941,0,0,1-.58-.2.952.952,0,0,1-.336-.5l-.011-.03a1.739,1.739,0,0,0-.614-.809,1.683,1.683,0,0,0-.992-.324,1.7,1.7,0,0,0-1.028.343,1.713,1.713,0,0,0-.609.87.887.887,0,0,1-.333.465.98.98,0,0,1-.577.181.943.943,0,0,1-.58-.2.952.952,0,0,1-.336-.5.38.38,0,0,0-.468-.265.38.38,0,0,0-.265.468,1.712,1.712,0,0,0,.6.9,1.706,1.706,0,0,0,1.044.355,1.742,1.742,0,0,0,1.022-.325,1.649,1.649,0,0,0,.615-.86.953.953,0,0,1,.339-.483.944.944,0,0,1,.571-.191.928.928,0,0,1,.546.179.989.989,0,0,1,.345.457l.358-.132-.367.1a1.713,1.713,0,0,0,.6.9,1.707,1.707,0,0,0,1.045.355,1.742,1.742,0,0,0,1.021-.325,1.65,1.65,0,0,0,.615-.86.954.954,0,0,1,.338-.483.948.948,0,0,1,.571-.191.946.946,0,0,1,.555.177.951.951,0,0,1,.342.454l.358-.128-.127-.358h0a.379.379,0,0,0-.24.46,1.713,1.713,0,0,0,.6.9,1.707,1.707,0,0,0,1.045.355,1.742,1.742,0,0,0,1.021-.325,1.649,1.649,0,0,0,.615-.86.38.38,0,0,0-.252-.474.382.382,0,0,0-.476.253Z"
            transform="translate(5018.085 9879.96)"
            fill={fill}
            stroke={fill}
            stroke-width="0.5"
          />
        </g>
      </g>
    </svg>
  );
}
