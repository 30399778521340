import React, { ChangeEvent, useEffect, useState } from "react";
import Sidebar from "../../../../components/Sidebar";
import { ApplicationState } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SESSIONSTORAGE from "../../../../helpers/constants/sessionStorage";
import LOCALSTORAGE from "../../../../helpers/constants/localStorage";
import { checkViewPermission } from "../../../../utils/permissions";
import PaginationComponent from "../../../../components/Pagination";
import {
  loadRequest,
  loadOpinionRequest,
} from "../../../../store/ducks/opinion/actions";
import {
  Box,
  Container,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import TabTittle from "../../../../components/Text/TabTittle";
import SearchComponent from "../../../../components/List/Search";
import Table from "../../../../components/Table";
import NoPermission from "../../../../components/Erros/NoPermission";
import { loadSelectSpecialityRequest } from "../../../../store/ducks/opinions/actions";

export default function AnswerOpinionList() {
  const integration = sessionStorage.getItem(SESSIONSTORAGE.INTEGRATION);
  const currentCompany = localStorage.getItem(LOCALSTORAGE.COMPANY_SELECTED);
  const [search, setSearch] = useState("");
  const [specialty, setSpecialty] = useState("TODAS");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const opinionState = useSelector(
    (state: ApplicationState) => state.opinionAnswer
  );
  const requestOpinionState = useSelector(
    (state: ApplicationState) => state.opinion
  );

  useEffect(() => {
    dispatch(
      loadRequest({
        status: specialty,
      })
    );
    dispatch(loadSelectSpecialityRequest("list"));
  }, [currentCompany, specialty]);

  const handleSearchInput = (event: any) => {
    dispatch(loadRequest({ search: event, status: specialty }));
  };

  const handleChangeInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(event.target.value);

    if (event.target.value === "") {
      handleSearchInput("");
    }
  };

  const handleKeyEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchInput(search);
    }
  };

  const handleClickSearch = (e: any) => {
    handleSearchInput(search);
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setSpecialty(event.target.value as string);
  };

  const rightsOfLayoutState = useSelector(
    (state: ApplicationState) => state.layout.data.rights
  );

  const medicalOpinionState = opinionState.list.data;
  return (
    <Sidebar>
      {checkViewPermission("opinion", JSON.stringify(rightsOfLayoutState)) ? (
        <Container>
          <TabTittle tittle="Resposta de Pareceres" />
          {integration ? (
            <>
              <Box display={"flex"} justifyContent={"space-between"}>
                <SearchComponent
                  onChangeInput={handleChangeInput}
                  handleButton={() => navigate("/opinion")}
                  inputPlaceholder="Pesquise por nome, nº de atendimento, etc..."
                  value={search}
                  onKeyEnter={handleKeyEnter}
                  onClickSearch={handleClickSearch}
                />
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={specialty}
                  onChange={handleChangeSelect}
                  size="small"
                  sx={{ backgroundColor: "#fff", minWidth: 170, height: 40 }}
                >
                  {requestOpinionState?.specialities?.map((speciality, i) => (
                    <MenuItem key={i} value={speciality}>
                      {speciality}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Table
                tableCells={[
                  { name: "Atendimento", align: "left" },
                  { name: "Paciente", align: "left" },
                  { name: "Data de Nascimento", align: "center" },
                  { name: "Especialidade", align: "left" },
                  { name: "Status do Parecer", align: "center" },
                ]}
                answerOpinionState={medicalOpinionState}
              >
                {""}
              </Table>
            </>
          ) : (
            <>
              <SearchComponent
                onChangeInput={handleChangeInput}
                handleButton={() => navigate("/opinion")}
                inputPlaceholder="Pesquise por nome, nº de atendimento, CPF, etc..."
                value={search}
                onKeyEnter={handleKeyEnter}
                onClickSearch={handleClickSearch}
              />
              <Table
                tableCells={[
                  { name: "Atendimento", align: "left" },
                  { name: "Paciente", align: "left" },
                  { name: "Data de Nascimento", align: "center" },
                  { name: "Especialidade", align: "left" },
                  { name: "Status do Parecer", align: "center" },
                ]}
                answerOpinionState={medicalOpinionState}
              >
                {""}
              </Table>
            </>
          )}
          <PaginationComponent
            page={opinionState.list.page}
            rowsPerPage={opinionState.list.limit}
            totalRows={opinionState.list.total}
            handleFirstPage={() =>
              dispatch(
                loadRequest({
                  page: "1",
                  limit: opinionState.list.limit,
                  total: opinionState.list.total,
                  status: specialty,
                  search,
                })
              )
            }
            handleLastPage={() =>
              dispatch(
                loadRequest({
                  page: Math.ceil(
                    +opinionState.list.total / +opinionState.list.limit
                  ).toString(),
                  limit: opinionState.list.limit,
                  total: opinionState.list.total,
                  status: specialty,
                  search,
                })
              )
            }
            handleNextPage={() =>
              dispatch(
                loadRequest({
                  page: (+opinionState.list.page + 1).toString(),
                  limit: opinionState.list.limit,
                  total: opinionState.list.total,
                  status: specialty,
                  search,
                })
              )
            }
            handlePreviosPage={() =>
              dispatch(
                loadRequest({
                  page: (+opinionState.list.page - 1).toString(),
                  limit: opinionState.list.limit,
                  total: opinionState.list.total,
                  status: specialty,
                  search,
                })
              )
            }
            handleChangeRowsPerPage={(event) => {
              dispatch(
                loadRequest({
                  limit: event.target.value,
                  page: "1",
                  status: specialty,
                  search,
                })
              );
            }}
          />
        </Container>
      ) : (
        <NoPermission />
      )}
    </Sidebar>
  );
}
