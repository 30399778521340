import React from "react";
interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}
export default function DrugIcon(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "17.835"}
      height={height ? height : "19"}
      viewBox="0 0 17.835 19"
    >
      <path
        id="Caminho_9072"
        data-name="Caminho 9072"
        d="M33.138,11.949a2.967,2.967,0,0,0-4.055-1.087l-1.507.87V8.51A1.869,1.869,0,0,0,27.2,7.4L25.349,4.923V3.87a1.115,1.115,0,0,0,.742-1.049V1.113A1.115,1.115,0,0,0,24.978,0H18.3a1.114,1.114,0,0,0-1.113,1.113V2.82a1.115,1.115,0,0,0,.742,1.049V4.923L16.072,7.4A1.868,1.868,0,0,0,15.7,8.51V15.7a1.857,1.857,0,0,0,1.855,1.855H23.52A2.966,2.966,0,0,0,27.552,18.6l4.5-2.6A2.971,2.971,0,0,0,33.138,11.949ZM17.927,1.113A.372.372,0,0,1,18.3.742h6.679a.372.372,0,0,1,.371.371V2.82a.371.371,0,0,1-.371.371H18.3a.371.371,0,0,1-.371-.371V1.113ZM16.666,7.842,18.6,5.269a.371.371,0,0,0,.074-.223V3.933h5.937v.742H18.364a.371.371,0,0,0,0,.742h6.428L26.61,7.842a1.123,1.123,0,0,1,.215.545H16.451A1.122,1.122,0,0,1,16.666,7.842Zm.891,8.968A1.114,1.114,0,0,1,16.443,15.7v-.375h6.742A2.973,2.973,0,0,0,23.2,16.81H17.556Zm5.92-2.226H16.443V9.128h10.39v3.032l-2.25,1.3A2.947,2.947,0,0,0,23.476,14.583Zm3.705,3.375A2.226,2.226,0,1,1,24.955,14.1l2.014-1.163L29.2,16.8Zm4.5-2.6-1.842,1.064-2.226-3.856L29.454,11.5a2.226,2.226,0,1,1,2.226,3.856Z"
        transform="translate(-15.701)"
        fill={fill}
      />
    </svg>
  );
}
