import {
  Autocomplete,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleHeader from "../../../../components/Header/SimpleHeader";
import theme from "../../../../theme/theme";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import LOCALSTORAGE from "../../../../helpers/constants/localStorage";
import {
  loadSelectPriorityRequest,
  loadSelectSpecialityRequest,
  createOpinionRequest
} from "../../../../store/ducks/opinions/actions";
import Loading from "../../../../components/Loading";
import { CreateOpinion } from "../../../../store/ducks/opinions/types";
interface CreateMedicalOpinionI {
  close: () => void;
  detailObj?: any;
}

export default function CreateMedicalOpinion(props: CreateMedicalOpinionI) {
  const dispatch = useDispatch();

  const opinionState = useSelector(
    (state: ApplicationState) => state.opinion
  );
  const external_user_id = localStorage.getItem(LOCALSTORAGE.SOLLAR_INTEGRATION_USER_ID) || "";
  const user_id = localStorage.getItem(LOCALSTORAGE.USER_ID) || "";
  const username = localStorage.getItem(LOCALSTORAGE.USERNAME) || "";

  useEffect(() => {
    dispatch(loadSelectSpecialityRequest('form'))
    dispatch(loadSelectPriorityRequest())
  }, [])


  const validationSchema = yup.object().shape({
    specialty: yup.object().required("É requerido"),
    priority: yup.object().required("É requerido"),
    justify: yup.string().required("O campo justificativa é obrigatório"),
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    resetField,
    trigger,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      specialty: "",
      priority: "",
      justify: ""
    },
  });

  useEffect(() => {
    if (opinionState.createdSuccess) {
      resetField("justify")
      reset()
      props.close()
    }
  }, [opinionState.createdSuccess])

  return (
    <Container sx={{ width: "calc(100% - 40px)", paddingX: 4, paddingY: 5 }}>
       {opinionState.loading && <Loading />}
      <SimpleHeader title="Solicitação de Parecer" />
      <form
        onSubmit={handleSubmit((obj: any) => {
          const createObj: CreateOpinion = {
            patient_name: opinionState.data.name,
            birthdate: opinionState.data.birthdate,
            external_attendance_id: String(opinionState.data.external_attendance_id),
            external_specialty_id: String(obj.specialty.id),
            external_specialty_name: obj.specialty.name,
            external_sector_name: opinionState.data.sector,
            requester_id: user_id,
            external_requester_id: external_user_id,
            reason: obj.justify,
            sector: opinionState.data.sector,
            priority_id: obj.priority._id,
          }
          dispatch(createOpinionRequest(createObj))
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.whiteBackgroud.main,
            color: theme.palette.text.primary,
            paddingX: 3,
            paddingY: 2,
            minHeight: "48px",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography fontWeight={550}>Prestador Solicitante:</Typography>
            <Typography>{username}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
            <Typography fontWeight={550}>Setor Paciente:</Typography>
            <Typography>{opinionState.data.sector}</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={1.5} mb={2.5}>
            <Controller
              control={control}
              name="specialty"
              render={({ field: { onChange, value }, fieldState }) => (
                <Autocomplete
                  sx={{
                    display: "inline-block",
                    "& input": {},
                  }}
                  options={opinionState.specialities}
                  onChange={(event, item) => {
                    onChange(item ? item : "");
                  }}
                  value={value ? value : ""}
                  getOptionLabel={(option: any) => option?.name || ''}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.name === value.name;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="secondary"
                      size="small"
                      label="Especialidade"
                      placeholder=""
                      sx={{ width: "100%" }}
                      error={!!fieldState.error?.message}
                      helperText={!!fieldState.error?.message && 'O campo especialidade é obrigatório'}
                    />
                  )}
                  size="small"
                />
              )}
            />
            <Controller
              control={control}
              name="priority"
              render={({ field: { onChange, value }, fieldState }) => (
                <Autocomplete
                  sx={{
                    display: "inline-block",
                    "& input": {},
                  }}
                  options={opinionState.priorities}
                  onChange={(event, item) => {
                    onChange(item ? item : "");
                  }}
                  value={value ? value : ""}
                  getOptionLabel={(option: any) => option?.name || ''}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.name === value.name;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="secondary"
                      size="small"
                      label="Prioridade"
                      placeholder=""
                      sx={{ width: "100%" }}
                      error={!!fieldState.error?.message}
                      helperText={!!fieldState.error?.message && 'O campo prioridade é obrigatório'}
                    />
                  )}
                  size="small"
                />
              )}
            />
            <Controller
              control={control}
              name="justify"
              render={({ field: { onChange, value }, fieldState }) => (
                <TextField
                  color="secondary"
                  size="small"
                  name="justify"
                  label="Justificativa"
                  multiline
                  placeholder="Obs..."
                  value={value}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  minRows={7}
                />
              )}
            />
          </Box>
          <Button type="submit" variant="contained" sx={{maxWidth: 240, width: "100%"}}>
            Solicitar
          </Button>
        </Box>
      </form>
      {
        props?.detailObj?.opinionanswers?.map((answers, index) => {
          return (
            <Container
              sx={{ width: "calc(100% - 40px)", paddingX: 4, paddingBottom: 5 }}
            >
              <SimpleHeader title="Resposta do Parecer" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: theme.palette.whiteBackgroud.main,
                  color: theme.palette.text.primary,
                  paddingX: 3,
                  paddingY: 3,
                  minHeight: "48px",
                }}
              >
                  <Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Resonsável:</Typography>
                      <Typography>{answers.answered_by}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Especialidade do Resonsável:</Typography>
                      <Typography>{answers.answered_by_speciality}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Data:</Typography>
                      <Typography>{answers.answered_at}</Typography>
                    </Box>
                    <Box display={"flex"} gap={1}>
                      <Typography fontWeight={550}>Resposta:</Typography>
                      <Typography>{answers.answer}</Typography>
                    </Box>
                  </Box>
              </Box>
            </Container>
          )
        })
      }
    </Container>
  );
}
