import { Reducer } from "redux";
import { IRevaluation, RevaluationState, RevaluationTypes } from "./types";

export const INITIAL_STATE: RevaluationState = {
  data: {
    items: [],
    limit: 10,
    total: 1,
    page: "1"
  },
  attendance: null,
  exams: {
    items: [],
    limit: 10,
    pages: { total: 1, current: 1 },
    totalItems: 1,
    attendance: "",
    page: "1",
  },
  loading: false,
  success: false,
  error: false,
};

const reducer: Reducer<RevaluationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RevaluationTypes.LOAD_REQUEST:
      return { ...state, loading: true, success: false };
    case RevaluationTypes.LOAD_REQUEST_CREATE_ATTENDANCE:
      return { ...state, loading: true, success: false };
    case RevaluationTypes.LOAD_REQUEST_UPDATE_STATUS:
      return { ...state, loading: true, success: false };
    case RevaluationTypes.LOAD_REQUEST_GET_EXAMS:
      return { ...state, loading: true, success: false };
    case RevaluationTypes.LOAD_REQUEST_GET_ATTENDANCE_BY_ID:
      return { ...state, loading: true, success: false };
    case RevaluationTypes.LOAD_SUCCESS:
      const data = action.payload;
      return {
        ...state,
        data: { ...data ,items: data.data },
        loading: false,
        success: true,
        error: false,
      };
    case RevaluationTypes.LOAD_SUCCESS_CREATE_ATTENDANCE:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case RevaluationTypes.LOAD_SUCCESS_GET_EXAMS:
      const dataExams = action.payload;
      return {
        ...state,
        exams: { ...dataExams, items: dataExams.data },
        loading: false,
        success: true,
        error: false,
      };
    case RevaluationTypes.LOAD_SUCCESS_UPDATE_STATUS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      }
    case RevaluationTypes.LOAD_SUCCESS_GET_ATTENDANCE_BY_ID:
      return {
        ...state,
        attendance: action.payload,
        loading: false,
        success: true,
        error: false,
      }
    case RevaluationTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    default:
      return state;
  }
};

export default reducer;
