import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}
export default function THCStype2(props: IPropsSvg) {
  const { fill, width, height } = props;
  // #273471
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "519.224"}
      height={height ? height : "80"}
      viewBox="0 0 519.224 190.821"
    >
      <g
        id="Grupo_11426"
        data-name="Grupo 11426"
        transform="translate(-692.537 -2305.217)"
      >
        <path
          id="path394"
          d="M472.745-38.936H468.32v9.156h4.425a4.985,4.985,0,0,0,3.526-1.206,4.388,4.388,0,0,0,1.313-3.4,4.289,4.289,0,0,0-1.313-3.367A5.091,5.091,0,0,0,472.745-38.936Zm.027,10.953H468.32v7.418h-2.074V-40.733h6.526a8.179,8.179,0,0,1,3.595.743,5.678,5.678,0,0,1,2.42,2.152,6.424,6.424,0,0,1,.871,3.451,6.59,6.59,0,0,1-.871,3.485,5.629,5.629,0,0,1-2.42,2.177,8.18,8.18,0,0,1-3.595.743"
          transform="translate(301.797 2510.99)"
          fill={fill}
        />
        <path
          id="path396"
          d="M502.087-19a4.5,4.5,0,0,0,2.558-.743,5.146,5.146,0,0,0,1.77-2.092,7.272,7.272,0,0,0,.65-3.182,7.03,7.03,0,0,0-.65-3.139,5.042,5.042,0,0,0-1.77-2.059,4.625,4.625,0,0,0-2.558-.726,4.578,4.578,0,0,0-2.532.726,5.048,5.048,0,0,0-1.77,2.059,7.03,7.03,0,0,0-.65,3.139,7.272,7.272,0,0,0,.65,3.182,5.152,5.152,0,0,0,1.77,2.092A4.456,4.456,0,0,0,502.087-19Zm0,1.688a6.686,6.686,0,0,1-2.766-.565,6.216,6.216,0,0,1-2.2-1.6,7.4,7.4,0,0,1-1.438-2.439,8.962,8.962,0,0,1-.512-3.1,8.943,8.943,0,0,1,.512-3.063,7.449,7.449,0,0,1,1.438-2.43,6.615,6.615,0,0,1,2.2-1.587,6.816,6.816,0,0,1,2.766-.557,6.884,6.884,0,0,1,2.792.557,6.626,6.626,0,0,1,2.2,1.587,7.511,7.511,0,0,1,1.438,2.43,8.964,8.964,0,0,1,.511,3.063,8.984,8.984,0,0,1-.511,3.1,7.46,7.46,0,0,1-1.438,2.439,6.227,6.227,0,0,1-2.2,1.6,6.753,6.753,0,0,1-2.792.565"
          transform="translate(291.178 2508.025)"
          fill={fill}
        />
        <path
          id="path398"
          d="M537.709-17.452l-3.817-12.194L530.1-17.452H528.14l-4.259-14.8h1.908l3.374,12.025,3.789-12.025h1.908l3.761,12.05,3.374-12.05h1.936l-4.286,14.8h-1.936"
          transform="translate(280.639 2507.879)"
          fill={fill}
        />
        <path
          id="path400"
          d="M569.179-17.332a6.605,6.605,0,0,1-3.581-.97,6.434,6.434,0,0,1-2.378-2.743,9.433,9.433,0,0,1-.844-4.1,8.374,8.374,0,0,1,.872-3.865,6.923,6.923,0,0,1,2.379-2.692,6.083,6.083,0,0,1,3.443-1,6.374,6.374,0,0,1,2.807.607,6.013,6.013,0,0,1,2.129,1.7,7.375,7.375,0,0,1,1.3,2.565,9.19,9.19,0,0,1,.291,3.274H563.427v-1.35h10.149a6.209,6.209,0,0,0-1.313-3.89,4.046,4.046,0,0,0-3.223-1.392,4.253,4.253,0,0,0-2.474.743,4.981,4.981,0,0,0-1.673,2.1,7.711,7.711,0,0,0-.608,3.2,8.767,8.767,0,0,0,.581,3.334,4.761,4.761,0,0,0,1.673,2.177,4.461,4.461,0,0,0,2.613.76,4.579,4.579,0,0,0,3.028-1,4.626,4.626,0,0,0,1.563-2.734h1.908a6.494,6.494,0,0,1-2.226,3.865,6.474,6.474,0,0,1-4.245,1.418"
          transform="translate(266.507 2508.045)"
          fill={fill}
        />
        <path
          id="path402"
          d="M595.293-25.5v7.915h-1.936v-14.8h1.8v3.367a6.3,6.3,0,0,1,2.267-2.608,5.843,5.843,0,0,1,3.291-.987v2.051a5.88,5.88,0,0,0-2.752.532,4.624,4.624,0,0,0-1.949,1.747,5.149,5.149,0,0,0-.719,2.785"
          transform="translate(255.133 2508.01)"
          fill={fill}
        />
        <path
          id="path404"
          d="M618.818-17.332a6.605,6.605,0,0,1-3.581-.97,6.429,6.429,0,0,1-2.378-2.743,9.432,9.432,0,0,1-.844-4.1,8.373,8.373,0,0,1,.871-3.865,6.937,6.937,0,0,1,2.379-2.692,6.083,6.083,0,0,1,3.443-1,6.371,6.371,0,0,1,2.807.607,6.018,6.018,0,0,1,2.13,1.7,7.376,7.376,0,0,1,1.3,2.565,9.172,9.172,0,0,1,.29,3.274H613.066v-1.35h10.149A6.214,6.214,0,0,0,621.9-29.8a4.042,4.042,0,0,0-3.222-1.392,4.258,4.258,0,0,0-2.475.743,4.993,4.993,0,0,0-1.673,2.1,7.711,7.711,0,0,0-.608,3.2,8.766,8.766,0,0,0,.581,3.334,4.76,4.76,0,0,0,1.672,2.177,4.465,4.465,0,0,0,2.613.76,4.583,4.583,0,0,0,3.029-1,4.629,4.629,0,0,0,1.562-2.734h1.908a6.49,6.49,0,0,1-2.226,3.865,6.471,6.471,0,0,1-4.245,1.418"
          transform="translate(248.284 2508.045)"
          fill={fill}
        />
        <path
          id="path406"
          d="M642.818-27.983a6.723,6.723,0,0,0,1.3,4.413,4.215,4.215,0,0,0,3.429,1.578,4.3,4.3,0,0,0,2.5-.734,4.781,4.781,0,0,0,1.66-2.093,8.023,8.023,0,0,0,.594-3.215,7.376,7.376,0,0,0-.622-3.173,4.754,4.754,0,0,0-1.688-2.025,4.309,4.309,0,0,0-2.446-.7,4.232,4.232,0,0,0-3.429,1.57,6.672,6.672,0,0,0-1.3,4.38Zm-1.965,0a9.558,9.558,0,0,1,.788-4,6.337,6.337,0,0,1,2.227-2.7,5.976,5.976,0,0,1,3.4-.97,5.214,5.214,0,0,1,2.959.886,5.76,5.76,0,0,1,2.047,2.489v-8.455h1.935v20.168h-1.8v-3.114a6.182,6.182,0,0,1-2.116,2.5,5.32,5.32,0,0,1-3.029.9,5.973,5.973,0,0,1-3.4-.97,6.361,6.361,0,0,1-2.227-2.709,9.706,9.706,0,0,1-.788-4.025"
          transform="translate(237.697 2510.99)"
          fill={fill}
        />
        <path
          id="path408"
          d="M699.176-27.983a6.628,6.628,0,0,0-1.314-4.38,4.274,4.274,0,0,0-3.443-1.57,4.326,4.326,0,0,0-2.433.7,4.628,4.628,0,0,0-1.688,2.025,7.523,7.523,0,0,0-.608,3.173,8.01,8.01,0,0,0,.595,3.215,4.735,4.735,0,0,0,1.673,2.093,4.273,4.273,0,0,0,2.461.734,4.256,4.256,0,0,0,3.443-1.578,6.679,6.679,0,0,0,1.314-4.413Zm1.964,0a9.693,9.693,0,0,1-.788,4.025,6.366,6.366,0,0,1-2.226,2.709,5.979,5.979,0,0,1-3.4.97,5.341,5.341,0,0,1-3.015-.9,6.14,6.14,0,0,1-2.129-2.5v3.114h-1.8V-40.733h1.936v8.481a5.767,5.767,0,0,1,2.033-2.515,5.192,5.192,0,0,1,2.973-.886,5.913,5.913,0,0,1,3.387.97,6.479,6.479,0,0,1,2.227,2.7,9.414,9.414,0,0,1,.8,4"
          transform="translate(220.469 2510.99)"
          fill={fill}
        />
        <path
          id="path410"
          d="M728.24-32.254l-7.466,20.227H718.7l2.129-5.62-5.78-14.607h2.074l4.7,12.244,4.453-12.244h1.963"
          transform="translate(210.46 2507.879)"
          fill={fill}
        />
        <path
          id="path412"
          d="M1113.149-220.444a33.481,33.481,0,0,1-5.522,19.012A36.707,36.707,0,0,1,1092-188.487q-10.1,4.646-24.462,4.65-14.05,0-24.462-4.81a39.158,39.158,0,0,1-16.413-13.654,42.081,42.081,0,0,1-6.943-20.674H1043.4q.473,9.468,6.551,15.156t17.124,5.679a31.876,31.876,0,0,0,11.045-1.738,16.831,16.831,0,0,0,7.5-5.131,12.464,12.464,0,0,0,2.762-8.126,10.7,10.7,0,0,0-4.025-8.675q-4.021-3.316-11.6-4.894l-18.307-3.468a54.176,54.176,0,0,1-11.915-3.713,36.3,36.3,0,0,1-9.628-6.236,26.708,26.708,0,0,1-6.548-9.147,30.232,30.232,0,0,1-2.366-12.312,30.582,30.582,0,0,1,5.443-17.915,36.576,36.576,0,0,1,14.914-12.228,50.973,50.973,0,0,1,21.778-4.422,52.717,52.717,0,0,1,22.569,4.658,40.007,40.007,0,0,1,16.017,13.257,38.191,38.191,0,0,1,6.706,20.438h-23.672a21.675,21.675,0,0,0-3.156-10.649,20.034,20.034,0,0,0-7.5-7.029,22.369,22.369,0,0,0-10.811-2.523,22.541,22.541,0,0,0-10.02,2.051,15.805,15.805,0,0,0-6.393,5.367,13.066,13.066,0,0,0-2.208,7.418,9.675,9.675,0,0,0,2.208,6.624,16.315,16.315,0,0,0,5.76,4.11,35.265,35.265,0,0,0,7.654,2.363l19.254,3.789q14.832,3,22.726,10.177t7.889,19.653"
          transform="translate(98.611 2606.223)"
          fill={fill}
        />
        <path
          id="path414"
          d="M913.521-223.5a35.229,35.229,0,0,1-3.165,5.629,32.037,32.037,0,0,1-11.125,9.949,30.8,30.8,0,0,1-14.912,3.629,31.89,31.89,0,0,1-13.652-2.844,30.65,30.65,0,0,1-10.415-7.89,35.7,35.7,0,0,1-6.709-12.152,48.645,48.645,0,0,1-2.366-15.468,47.932,47.932,0,0,1,2.366-15.384,35.814,35.814,0,0,1,6.709-12.076,29.791,29.791,0,0,1,10.415-7.806,32.507,32.507,0,0,1,13.652-2.768,31.43,31.43,0,0,1,14.912,3.553,32.009,32.009,0,0,1,11.206,9.865,33.643,33.643,0,0,1,2.873,4.97h25.608a53.047,53.047,0,0,0-7.727-15.94,54.739,54.739,0,0,0-20.123-17.122A59.374,59.374,0,0,0,884-301.506a54.869,54.869,0,0,0-22.173,4.5,56.311,56.311,0,0,0-18.147,12.54,58.008,58.008,0,0,0-12.153,18.7,60.71,60.71,0,0,0-4.339,23.122,61.255,61.255,0,0,0,4.339,23.2,58.627,58.627,0,0,0,12.153,18.86,55.644,55.644,0,0,0,18.147,12.624,54.872,54.872,0,0,0,22.173,4.5,58.9,58.9,0,0,0,26.987-6.152,54.906,54.906,0,0,0,20.042-17.122,54.29,54.29,0,0,0,8.035-16.759H913.521"
          transform="translate(169.292 2606.723)"
          fill={fill}
        />
        <path
          id="path416"
          d="M406.158-287.15l35.79,10.228v-20.97h-95v20.987h35.823v92.318h23.356l.035-102.562"
          transform="translate(345.593 2605.395)"
          fill={fill}
        />
        <path
          id="path418"
          d="M785.548-256.483v-41.29H762.191v34.615l23.356,6.675"
          transform="translate(193.153 2605.352)"
          fill={fill}
        />
        <path
          id="path420"
          d="M719.717-252.955H669.848v-44.817H646.492v113.313h23.356v-48.134h49.869v48.134h23.356v-68.5H719.717"
          transform="translate(235.627 2605.352)"
          fill={fill}
        />
        <path
          id="path422"
          d="M543.108-227.786v-27.56H524.734v27.56H497.172v18.371h4.594l32.155-9.181,32.154,9.181h4.594v-18.371H543.108"
          transform="translate(290.443 2589.777)"
          fill={fill}
        />
        <path
          id="path424"
          d="M549.908-175.506l-9.188,2.624v20.337H559.1v-20.337l-9.187-2.624"
          transform="translate(274.457 2560.467)"
          fill={fill}
        />
        <path
          id="path426"
          d="M774.885-25.876h3.253v6.4h-5.077a10.72,10.72,0,0,1-5.053-1.131,7.7,7.7,0,0,1-3.278-3.283,10.734,10.734,0,0,1-1.133-5.1V-40.323h-4.831v-1.527L769.314-53.04h1.331v6.953h7.345v5.764h-7.2v10.4a4.014,4.014,0,0,0,1.059,2.979,4.1,4.1,0,0,0,3.032,1.063"
          transform="translate(194.411 2515.508)"
          fill={fill}
        />
        <path
          id="path428"
          d="M801.736-25.164a6.983,6.983,0,0,0,5.175,2.067,7.068,7.068,0,0,0,5.2-2.067,7.087,7.087,0,0,0,2.071-5.223,7.093,7.093,0,0,0-2.071-5.232,7.037,7.037,0,0,0-5.175-2.067,7.258,7.258,0,0,0-3.721.962,6.912,6.912,0,0,0-2.588,2.616,7.389,7.389,0,0,0-.936,3.722A7.134,7.134,0,0,0,801.736-25.164Zm14.122-18.531h5.372V-17.08h-5.668l-.592-2.371a12.73,12.73,0,0,1-8.626,3.156,13.425,13.425,0,0,1-9.981-4.017A13.745,13.745,0,0,1,792.4-30.387,13.617,13.617,0,0,1,796.363-40.4a13.47,13.47,0,0,1,9.981-3.991,12.659,12.659,0,0,1,8.725,3.249l.789-2.557"
          transform="translate(182.065 2512.332)"
          fill={fill}
        />
        <path
          id="path430"
          d="M856.075-16.295a13.738,13.738,0,0,1-5.841-1.181,9.187,9.187,0,0,1-3.943-3.35,8.947,8.947,0,0,1-1.405-4.979h7a3.663,3.663,0,0,0,.616,1.992,3.222,3.222,0,0,0,1.553,1.19,6.477,6.477,0,0,0,2.267.363,5.471,5.471,0,0,0,1.873-.3,3.185,3.185,0,0,0,1.38-.911,2.117,2.117,0,0,0,.542-1.451,1.931,1.931,0,0,0-1.281-1.823,16.208,16.208,0,0,0-3.845-1.038c-1.084-.16-1.98-.329-2.687-.489a16.787,16.787,0,0,1-2.514-.861,8.2,8.2,0,0,1-2.317-1.409,6.706,6.706,0,0,1-1.5-2.169,7.526,7.526,0,0,1-.641-3.207,7.478,7.478,0,0,1,2.958-6.11,12.048,12.048,0,0,1,7.763-2.363,11.7,11.7,0,0,1,7.739,2.439,7.961,7.961,0,0,1,2.932,6.43H859.87q0-3.152-3.992-3.156a4.359,4.359,0,0,0-2.613.692,2.214,2.214,0,0,0-.937,1.873,1.635,1.635,0,0,0,.272.911,2.215,2.215,0,0,0,.64.666,4,4,0,0,0,1.159.464,11.847,11.847,0,0,0,1.331.321c.361.068.9.169,1.626.3q.937.2,1.331.279c.263.042.706.135,1.331.27a9.612,9.612,0,0,1,1.4.371c.312.11.739.27,1.282.464a4.727,4.727,0,0,1,1.232.616c.279.211.624.473,1.035.768a3.717,3.717,0,0,1,.961.979q.344.544.69,1.19a4.436,4.436,0,0,1,.468,1.477,12.2,12.2,0,0,1,.123,1.772,7.936,7.936,0,0,1-3.056,6.532,12.565,12.565,0,0,1-8.083,2.439"
          transform="translate(162.796 2512.332)"
          fill={fill}
        />
        <path
          id="path432"
          d="M898.5-16.295a14.175,14.175,0,0,1-7.172-1.848,13.38,13.38,0,0,1-5.077-5.1,14.332,14.332,0,0,1-1.848-7.2,13.426,13.426,0,0,1,4.041-9.958,13.78,13.78,0,0,1,10.105-3.991,13.715,13.715,0,0,1,8.872,2.979,13.136,13.136,0,0,1,4.683,7.915h-7.147a7.1,7.1,0,0,0-2.587-3.038,6.969,6.969,0,0,0-3.87-1.106,6.4,6.4,0,0,0-4.855,2.068,7.267,7.267,0,0,0-1.947,5.181,7.39,7.39,0,0,0,1.971,5.223,6.352,6.352,0,0,0,4.83,2.118,6.629,6.629,0,0,0,3.845-1.156,7.573,7.573,0,0,0,2.613-3.334H912.2a13.522,13.522,0,0,1-4.732,8.16,13.648,13.648,0,0,1-8.971,3.08"
          transform="translate(148.289 2512.332)"
          fill={fill}
        />
        <path
          id="path434"
          d="M946.483-23.046a6.757,6.757,0,0,0,5.077-2.042,7.268,7.268,0,0,0,1.972-5.257,7.259,7.259,0,0,0-1.972-5.249,6.786,6.786,0,0,0-5.1-2.042,6.784,6.784,0,0,0-5.1,2.042,7.256,7.256,0,0,0-1.971,5.249,8.364,8.364,0,0,0,.517,2.962,6.315,6.315,0,0,0,1.454,2.312,6.875,6.875,0,0,0,2.243,1.485A7.424,7.424,0,0,0,946.483-23.046Zm0,6.751a15.039,15.039,0,0,1-7.394-1.823,13.14,13.14,0,0,1-5.151-5.055,14.171,14.171,0,0,1-1.848-7.173A13.442,13.442,0,0,1,936.181-40.4a14.131,14.131,0,0,1,10.3-3.991A14.069,14.069,0,0,1,956.735-40.4a13.446,13.446,0,0,1,4.091,10.051,13.433,13.433,0,0,1-4.115,10.033,14.043,14.043,0,0,1-10.228,4.017"
          transform="translate(130.782 2512.332)"
          fill={fill}
        />
        <path
          id="path436"
          d="M1023.961-22.071V-32.518a10.962,10.962,0,0,0-2.636-7.586,8.658,8.658,0,0,0-6.774-2.962,8.941,8.941,0,0,0-1.651.152,11.966,11.966,0,0,0-1.576.388,9.334,9.334,0,0,0-1.454.616,12.187,12.187,0,0,0-1.33.844,9.353,9.353,0,0,0-1.182,1.029,11.775,11.775,0,0,0-1.035,1.258,8.154,8.154,0,0,0-3.154-3.156,9.152,9.152,0,0,0-4.532-1.131A9.612,9.612,0,0,0,994.227-42a10.77,10.77,0,0,0-3.572,2.878l-1.33-3.3H984.3V-15.81h7.193V-30.2a7.543,7.543,0,0,1,1.38-4.835,4.647,4.647,0,0,1,3.842-1.722q3.892,0,3.893,5.418V-15.81h7.045V-30.2a9.57,9.57,0,0,1,.592-3.553,4.687,4.687,0,0,1,1.749-2.244,4.869,4.869,0,0,1,2.735-.76q4.039,0,4.04,5.418V-15.81h1.224l5.969-6.261"
          transform="translate(111.616 2511.848)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
