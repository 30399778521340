import React from "react";
interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}
export default function PrescriptionIcon(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "39.424"}
      height={height ? height : "42"}
      viewBox="0 0 39.424 42"
    >
      <path
        id="Caminho_9072"
        data-name="Caminho 9072"
        d="M54.246,26.413a6.558,6.558,0,0,0-8.964-2.4L41.95,25.935V18.811a4.131,4.131,0,0,0-.82-2.461l-4.1-5.468V8.554a2.465,2.465,0,0,0,1.641-2.32V2.461A2.464,2.464,0,0,0,36.208,0H21.443a2.464,2.464,0,0,0-2.461,2.461V6.234a2.465,2.465,0,0,0,1.641,2.32v2.329l-4.1,5.469a4.13,4.13,0,0,0-.82,2.46V34.7a4.106,4.106,0,0,0,4.1,4.1H32.985a6.556,6.556,0,0,0,8.914,2.32l9.945-5.742A6.567,6.567,0,0,0,54.246,26.413ZM20.623,2.461a.821.821,0,0,1,.82-.82H36.208a.821.821,0,0,1,.82.82V6.234a.821.821,0,0,1-.82.82H21.443a.821.821,0,0,1-.82-.82V2.461ZM17.834,17.335,22.1,11.648a.82.82,0,0,0,.164-.492V8.695H35.387v1.641h-13.8a.82.82,0,0,0,0,1.641H35.8l4.019,5.358a2.482,2.482,0,0,1,.475,1.2H17.359A2.481,2.481,0,0,1,17.834,17.335ZM19.8,37.158A2.464,2.464,0,0,1,17.342,34.7v-.828h14.9a6.573,6.573,0,0,0,.039,3.289H19.8Zm13.086-4.922H17.342V20.179H40.309v6.7l-4.973,2.871A6.513,6.513,0,0,0,32.888,32.237ZM41.079,39.7a4.922,4.922,0,1,1-4.922-8.524L40.609,28.6l4.922,8.524Zm9.945-5.742-4.072,2.351L42.03,27.782,46.1,25.432a4.921,4.921,0,1,1,4.922,8.524Z"
        transform="translate(-15.701)"
        fill={fill}
      />
    </svg>
  );
}
