import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import SwitchField from "../../../components/FormFields/SwitchField";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { updateSettingsRequest } from "../../../store/ducks/customers/actions";

export default function ClientConfiguration() {
  const dispatch = useDispatch();
  const customerState = useSelector((state: ApplicationState) => state.customers);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      enableIntegrationEditInUserRegistry: false,
      enableRecoveryPassword: false,
    },
  });

  useEffect(() => {
    if (customerState?.data?.settings) {
      reset({
        enableIntegrationEditInUserRegistry: customerState?.data?.settings?.enable_integration_edit_in_user_registry,
        enableRecoveryPassword: customerState?.data?.settings?.enable_recovery_password
      })
    }
  }, [customerState.data, customerState.error])

  const onSubmit = (values) => {
    dispatch(updateSettingsRequest({
      settings: {
        document: [],
        enable_recovery_password: values.enableRecoveryPassword,
        enable_integration_edit_in_user_registry: values.enableIntegrationEditInUserRegistry
      }
    }))
  }

  return (
    <Grid container component="form" mx={2} my={1} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <SwitchField
          control={control}
          name="enableIntegrationEditInUserRegistry"
          label="Permitir edição dos dados da integração no cadastro de usuário"
        />
      </Grid>
      <Grid item xs={12}>
        <SwitchField
          control={control}
          name="enableRecoveryPassword"
          label="Permitir recuperação de senha"
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Button variant="contained" type="submit">
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}
