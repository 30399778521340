import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";

import {
  loadSuccess,
  loadFailure,
  loadOpinionSuccess,
  loadOpinionFailure,
  loadSelectPrioritySuccess,
  loadSelectPriorityFailure,
  loadSelectSpecialitySuccess,
  loadSelectSpecialityFailure,
  createOpinionRequestSuccess,
  createOpinionRequestFailure
} from "./actions";

import { apiSollar } from "../../../services/axios";
import { CreateOpinion } from "./types";

const token = localStorage.getItem("token");

export function* get({ payload }: any) {
  try {
    const { params } = payload;
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `/opinion/?limit=${params.limit ? params.limit : 10}&page=${
        params.page || 1
      }${params.search ? "&search=" + params.search : ""}${
        params.status ? "&status=" + params.status : ""
      }${params.patient_id ? "&patient_id=" + params.patient_id : ""}`
    );

    yield put(loadSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao buscar a lista de parecer");
    yield put(loadFailure());
  }
}

export function* getData({ payload }: any) {
  try {
    const { params } = payload;
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `/opinion/${params.id}`
    );

    yield put(loadOpinionSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao buscar os dados do parecer");
    yield put(loadOpinionFailure());
  }
}

export function* listSpecialities({ payload }: any) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `/speciality/?type=${payload}`
    );

    yield put(loadSelectSpecialitySuccess(response.data));
  } catch (error) {
    toast.error("Erro ao buscar a lista de especialidades");
    yield put(loadSelectSpecialityFailure());
  }
}

export function* listPriorities({ payload }: any) {
  try {
    const { params } = payload;
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `/priority`
    );

    yield put(loadSelectPrioritySuccess(response.data));
  } catch (error) {
    toast.error("Erro ao buscar a lista de prioridades");
    yield put(loadSelectPriorityFailure());
  }
}

export function* createOpinion({ payload }: any) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.post as any,
      `/opinion`,
      payload,
    );

    yield put(createOpinionRequestSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao criar o parecer");
    yield put(createOpinionRequestFailure());
  }
}
