import { Reducer } from "redux";
import { PanelState, PanelTypes } from "./types";

export const INITIAL_STATE: PanelState = {
  data: [{
    id: 1,
    patient_name: "",
    birthdate: "",
    medicament: [],
    hr_medicament: [],
    initial_date: [],
    final_date: [],
    period: [],
    origin_id: 1,
    dose: [],
    diagnostics: "",
  }],
  origin: [{id: 1, origin_name: "TODAS"}],
  loading: false,
  success: false,
  error: false
};

const reducer: Reducer<PanelState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PanelTypes.LOAD_REQUEST:
      return { ...state, loading: true, success: false };
    case PanelTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
      };
    case PanelTypes.LOAD_REQUEST_ORIGIN:
      return { ...state, loading: true, success: false };
    case PanelTypes.LOAD_SUCCESS_ORIGIN:
      return {
        ...state,
        origin: action.payload,
        loading: false,
        success: true,
        error: false,
      };
    case PanelTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
      default:
      return state;
  }
};

export default reducer;
