import React from "react";

interface IPropsSvg {
  fill: any;
  fill2?: any;
  width?: any;
  height?: any;
}

export default function PersonData(props: IPropsSvg) {
  const { fill, fill2, width, height } = props;

  return (
    <svg
      width={width ? width : "32"}
      height={height ? height : "31"}
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_67_161)">
        <path
          d="M16.282 31C24.8424 31 31.782 24.0604 31.782 15.5C31.782 6.93959 24.8424 0 16.282 0C7.72157 0 0.781982 6.93959 0.781982 15.5C0.781982 24.0604 7.72157 31 16.282 31Z"
          fill={fill}
        />
        <path
          d="M10.704 5.877H17.904C18.1028 5.87717 18.2935 5.95631 18.434 6.097L23.834 11.497C23.9747 11.6375 24.0538 11.8282 24.054 12.027V22.837C24.0532 23.5133 23.7842 24.1617 23.3059 24.64C22.8277 25.1182 22.1793 25.3872 21.503 25.388H10.704C10.0278 25.3869 9.3797 25.1178 8.90169 24.6396C8.42367 24.1614 8.15479 23.5131 8.15399 22.837V8.427C8.15505 7.75102 8.42405 7.10303 8.90204 6.62505C9.38003 6.14706 10.028 5.87806 10.704 5.877ZM17.604 7.377H10.704C10.4257 7.37753 10.1589 7.48832 9.96211 7.68512C9.76531 7.88192 9.65452 8.14868 9.65399 8.427V22.837C9.65426 23.1155 9.76493 23.3825 9.96176 23.5795C10.1586 23.7765 10.4255 23.8875 10.704 23.888H21.512C21.7907 23.8877 22.0578 23.7769 22.2549 23.5799C22.4519 23.3828 22.5627 23.1157 22.563 22.837V12.341L17.604 7.377Z"
          fill={fill2 ? fill2 : "white"}
        />
        <path
          d="M23.31 12.78H17.91C17.7111 12.78 17.5203 12.701 17.3796 12.5603C17.239 12.4197 17.16 12.2289 17.16 12.03V6.627C17.16 6.42809 17.239 6.23732 17.3796 6.09667C17.5203 5.95602 17.7111 5.877 17.91 5.877C18.1089 5.877 18.2997 5.95602 18.4403 6.09667C18.581 6.23732 18.66 6.42809 18.66 6.627V11.28H23.31C23.4085 11.28 23.506 11.2994 23.597 11.3371C23.688 11.3748 23.7707 11.43 23.8403 11.4997C23.9099 11.5693 23.9652 11.652 24.0029 11.743C24.0406 11.834 24.06 11.9315 24.06 12.03C24.06 12.1285 24.0406 12.226 24.0029 12.317C23.9652 12.408 23.9099 12.4907 23.8403 12.5603C23.7707 12.63 23.688 12.6852 23.597 12.7229C23.506 12.7606 23.4085 12.78 23.31 12.78Z"
          fill={fill2 ? fill2 : "white"}
        />
        <path
          d="M19.706 17.283H12.506C12.3071 17.283 12.1163 17.204 11.9757 17.0633C11.835 16.9227 11.756 16.7319 11.756 16.533C11.756 16.3341 11.835 16.1433 11.9757 16.0027C12.1163 15.862 12.3071 15.783 12.506 15.783H19.706C19.9049 15.783 20.0957 15.862 20.2363 16.0027C20.377 16.1433 20.456 16.3341 20.456 16.533C20.456 16.7319 20.377 16.9227 20.2363 17.0633C20.0957 17.204 19.9049 17.283 19.706 17.283Z"
          fill={fill2 ? fill2 : "white"}
        />
        <path
          d="M19.706 20.886H12.506C12.3071 20.886 12.1163 20.807 11.9757 20.6663C11.835 20.5257 11.756 20.3349 11.756 20.136C11.756 19.9371 11.835 19.7463 11.9757 19.6057C12.1163 19.465 12.3071 19.386 12.506 19.386H19.706C19.9049 19.386 20.0957 19.465 20.2363 19.6057C20.377 19.7463 20.456 19.9371 20.456 20.136C20.456 20.3349 20.377 20.5257 20.2363 20.6663C20.0957 20.807 19.9049 20.886 19.706 20.886Z"
          fill={fill2 ? fill2 : "white"}
        />
        <path
          d="M14.306 13.681H12.506C12.3071 13.681 12.1163 13.602 11.9757 13.4613C11.835 13.3207 11.756 13.1299 11.756 12.931C11.756 12.7321 11.835 12.5413 11.9757 12.4007C12.1163 12.26 12.3071 12.181 12.506 12.181H14.306C14.5049 12.181 14.6957 12.26 14.8363 12.4007C14.977 12.5413 15.056 12.7321 15.056 12.931C15.056 13.1299 14.977 13.3207 14.8363 13.4613C14.6957 13.602 14.5049 13.681 14.306 13.681Z"
          fill={fill2 ? fill2 : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_67_161">
          <rect
            width="31"
            height="31"
            fill={fill2 ? fill2 : "white"}
            transform="translate(0.781982)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
