import React, { useEffect, useMemo, useState } from "react";
// components
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ButtonTabs, { IButtons } from "../../../components/Button/ButtonTabs";
import CardInfo from "../../../components/Card/Info";
import Header from "../../../components/Header/Overview";
import Sidebar from "../../../components/Sidebar";
// services
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Drawer from "../../../components/Drawer";
import AttestIcon from "../../../components/Icons/Attest";
import EvolutionIcon from "../../../components/Icons/Evolution";
import FormIcon from "../../../components/Icons/Form";
import PatientIcon from "../../../components/Icons/Patient";
import THCStype1 from "../../../components/Icons/THCS_Type1";
import SignaturePassword from "../../../components/SignaturePassword";
import TabsComponent from "../../../components/TabsComponent";
import LOCALSTORAGE from "../../../helpers/constants/localStorage";
import SESSIONSTORAGE from "../../../helpers/constants/sessionStorage";
import { ApplicationState } from "../../../store";
import { openPopup } from "../../../store/ducks/digitalSignature/action";
import {
  loadRequestAttendanceById,
  loadRequestCreateMeet,
  loadRequestGetBiomeasuresByAttendance,
  loadRequestGetDocumentsByType,
  loadRequestUpdateStatus,
} from "../../../store/ducks/telemedicineAttendances/actions";
import { ITelemedicineDocs } from "../../../store/ducks/telemedicineAttendances/types";
import theme from "../../../theme/theme";
import EletronicalDocuments from "../../integratedDocuments/eletronicalDocuments";
import ImageExams from "../../integratedDocuments/imageExams";
import LaboratorialExams from "../../integratedDocuments/laboratorialExams";
import InitialCare from "./forms/initialCare";
import MedicalCertificate from "./forms/medicalCertificate";
import Prescription from "./forms/prescription";
import { ReactComponent as DocumentIcon } from "../../../assets/img/icon-documents.svg";

export default function TelemedicineOverview() {
  const integration = sessionStorage.getItem(SESSIONSTORAGE.INTEGRATION);
  const currentCompanyId = localStorage.getItem(LOCALSTORAGE.COMPANY_SELECTED);
  const signerTokenExpiredAt = localStorage.getItem(
    LOCALSTORAGE.SIGNER_TOKEN_EXPIRED_AT
  );
  const signerToken = localStorage.getItem(LOCALSTORAGE.SIGNER_TOKEN);
  const telemedicineUrl = localStorage.getItem(LOCALSTORAGE.TELEMEDICINE_URL);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [createDocument, setCreateDocument] = useState(false);
  const [createDocumentInCall, setCreateDocumentInCall] = useState(true);
  const [openVideoCall, setOpenVideoCall] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogElement, setDialogElement] = useState<{
    title?: string;
    element: React.ReactNode;
    anchor?: "left" | "right" | "top" | "bottom" | undefined;
  } | null>(null);
  const [documentType, setDocumentType] =
    useState<ITelemedicineDocs>("InitialCare");
  const [refetch, setRefetch] = useState(false);

  const telemedicineAttendancesState = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances
  );

  const digitalSignatureState = useSelector(
    (state: ApplicationState) => state.digitalSignature
  );

  const userState = useSelector((state: ApplicationState) => state.users);
  const userIsDoctor = userState?.data?.council === "CRM";

  const currentAttendance = telemedicineAttendancesState.data.attendance;

  const existsDocumentCanceledSimple =
    !!telemedicineAttendancesState?.documentList?.items?.simplePrescription
      ?.length &&
    !telemedicineAttendancesState?.documentList?.items?.simplePrescription[0]
      ?.canceled;

  const existsDocumentCanceledSpecial =
    !!telemedicineAttendancesState?.documentList?.items?.specialPrescription
      ?.length &&
    !telemedicineAttendancesState?.documentList?.items?.specialPrescription[0]
      ?.canceled;

  const existsDocumentCanceledInitialCare =
    !!telemedicineAttendancesState?.documentList?.items?.initialCare?.length &&
    !telemedicineAttendancesState?.documentList?.items?.initialCare[0]
      ?.canceled;

  const existsDocumentCanceledMedicalCertificate =
    !!telemedicineAttendancesState?.documentList?.items?.medicalCertificate
      ?.length &&
    !telemedicineAttendancesState?.documentList?.items?.medicalCertificate[0]
      ?.canceled;

  useEffect(() => {
    dispatch(loadRequestAttendanceById({ attendance_id: params.id }));
  }, [refetch]);

  useEffect(() => {
    dispatch(
      loadRequestGetDocumentsByType({
        document_type: documentType,
        telemedicine_attendance_id:
          currentAttendance?._id ||
          telemedicineAttendancesState?.meet?._id ||
          null,
      })
    );
    if (documentType === "InitialCare") {
      dispatch(
        loadRequestGetBiomeasuresByAttendance({
          attendance_id: params.id,
        })
      );
    }
    setRefetch(false);
  }, [documentType, refetch, currentAttendance]);

  const telemedicineId = !!currentAttendance?._id
    ? currentAttendance?._id
    : telemedicineAttendancesState?.meet?._id
    ? telemedicineAttendancesState?.meet?._id
    : null;

  const roomId = useMemo(() => uuidv4(), [currentAttendance]);

  const tabsIntegratedDocuments = [
    {
      label: "Exames de Imagem",
      content: <ImageExams currentAttendance={currentAttendance} />,
    },
    {
      label: "Documentos de Prontuário",
      content: <EletronicalDocuments currentAttendance={currentAttendance} />,
    },
    {
      label: "Exames Laboratoriais",
      content: <LaboratorialExams currentAttendance={currentAttendance} />,
    },
  ];

  const tabs = [
    {
      label: "Ficha de Atendimento",
      content: (
        <>
          <Box display="flex" justifyContent="end" mr={3.6} mb={1.4}>
            <Button
              disabled={
                !telemedicineId ||
                !userIsDoctor ||
                existsDocumentCanceledInitialCare
              }
              variant="contained"
              onClick={() => {
                setCreateDocument(!createDocument);
                setDocumentType("InitialCare");
              }}
            >
              {createDocument ? "Cancelar" : "Novo Documento"}
            </Button>
          </Box>
          <InitialCare
            createNewDocument={createDocument}
            setCreateNewDocument={setCreateDocument}
            setRefetch={setRefetch}
            telemedicineId={telemedicineId}
          />
        </>
      ),
      type: "InitialCare",
    },
    {
      label: "Receituário Simples",
      content: (
        <>
          <Box display="flex" justifyContent="end" mr={3.6} mb={1.4}>
            <Button
              disabled={
                !telemedicineId || !userIsDoctor || existsDocumentCanceledSimple
              }
              variant="contained"
              onClick={() => {
                setCreateDocument(!createDocument);
                setDocumentType("SimplePrescription");
              }}
            >
              {createDocument ? "Cancelar" : "Novo Documento"}
            </Button>
          </Box>
          <Prescription
            createNewDocument={createDocument}
            setCreateNewDocument={setCreateDocument}
            telemedicineId={telemedicineId}
            setRefetch={setRefetch}
            type={"simple"}
          />
        </>
      ),
      type: "SimplePrescription",
    },
    {
      label: "Receituário Especial",
      content: (
        <>
          <Box display="flex" justifyContent="end" mr={3.6} mb={1.4}>
            <Button
              disabled={
                !telemedicineId ||
                !userIsDoctor ||
                existsDocumentCanceledSpecial
              }
              variant="contained"
              onClick={() => {
                setCreateDocument(!createDocument);
                setDocumentType("SpecialPrescription");
              }}
            >
              {createDocument ? "Cancelar" : "Novo Documento"}
            </Button>
          </Box>
          <Prescription
            createNewDocument={createDocument}
            setCreateNewDocument={setCreateDocument}
            telemedicineId={telemedicineId}
            setRefetch={setRefetch}
            type={"special"}
          />
        </>
      ),
      type: "SpecialPrescription",
    },
    {
      label: "Atestado do dia",
      content: (
        <>
          <Box display="flex" justifyContent="end" mr={3.6} mb={1.4}>
            <Button
              disabled={
                !telemedicineId ||
                !userIsDoctor ||
                existsDocumentCanceledMedicalCertificate
              }
              variant="contained"
              onClick={() => {
                setCreateDocument(!createDocument);
                setDocumentType("MedicalCertificate");
              }}
            >
              {createDocument ? "Cancelar" : "Novo Documento"}
            </Button>
          </Box>
          <MedicalCertificate
            createNewDocument={createDocument}
            setCreateNewDocument={setCreateDocument}
            telemedicineId={telemedicineId}
            setRefetch={setRefetch}
          />
        </>
      ),
      type: "MedicalCertificate",
    },
    {
      label: "Documentos Integrados",
      content: (
        <TabsComponent
          tabs={tabsIntegratedDocuments}
          tabListSx={{ justifyContent: "center" }}
        />
      ),
    },
  ];

  const buttons: IButtons[] = [
    {
      name: "Voltar",
      disabled: openVideoCall,
      onClick: () => {
        if (createDocument) {
          setCreateDocument(false);
        } else if (openVideoCall) {
          setOpenVideoCall(false);
        } else {
          navigate("/telemedicine");
        }
      },
      variant: "contained",
      background: "primary",
      show: true,
    },
  ];

  const rows = [
    { name: "Nome", value: currentAttendance?.patient_name },
    {
      name: "Gênero",
      value: currentAttendance?.gender === "F" ? "Feminino" : "Masculino",
    },
    { name: "Estado Civil", value: currentAttendance?.marital_status },
    { name: "Data de nascimento", value: currentAttendance?.birthdate },
    { name: "Mãe", value: currentAttendance?.mother_name },
    { name: "RG", value: currentAttendance?.ident_national },
    { name: "CPF", value: currentAttendance?.physic_national },
    { name: "Celular", value: currentAttendance?.phone_number },
    { name: "Email", value: currentAttendance?.email },
    {
      name: "Código do Prontuário",
      value: currentAttendance?.id_medical_record,
    },
    { name: "Número do Atendimento", value: currentAttendance?.id_attendance },
    { name: "Médico Assistente", value: currentAttendance?.employee_name },
    { name: "Data de Atendimento", value: currentAttendance?.attendance_date },
    { name: "Setor", value: currentAttendance?.sector_name },
    {
      name: "Especialidade do Atendimento",
      value: currentAttendance?.specialty,
    },
    { name: "Convênio", value: { name: currentAttendance?.insurance } },
    { name: "Plano", value: { name: currentAttendance?.plan } },
  ];

  const content = {
    tittle: "HeaderOverview",
    rows: rows,
    onClickCreateMeet: () => {
      if (!telemedicineAttendancesState.meet?.room?.urls?.patient) {
        dispatch(
          loadRequestCreateMeet({
            company_id: currentCompanyId,
            external_ids: {
              patient: currentAttendance?.id_medical_record,
              attendance: currentAttendance?.id_attendance,
            },
            room_id: String(roomId),
            provider: {
              name: userState.data?.name,
              email: userState.data?.email,
            },
            patient: {
              name: currentAttendance?.patient_name,
              email: currentAttendance?.email,
            },
          })
        );
        setOpenVideoCall(true);
        setRefetch(true);
      }
    },
    onClickJoinMeet: () => {
      if (
        telemedicineAttendancesState.meet?.room?.id ||
        currentAttendance?.room_id
      ) {
        setOpenVideoCall(true);
      }
    },
    onClickFinish: () => {
      if (!digitalSignatureState?.data?.token && !signerToken) {
        dispatch(openPopup());
      } else {
        if (new Date() > new Date(signerTokenExpiredAt)) {
          localStorage.setItem(LOCALSTORAGE.SIGNER_TOKEN, null);
          toast.error(
            "O token de assinatura está expirado, realize o login novamente"
          );
          dispatch(openPopup());
        } else {
          dispatch(
            loadRequestUpdateStatus({
              _id: !!currentAttendance?._id
                ? currentAttendance?._id
                : telemedicineAttendancesState?.meet?._id,
              finished_by: userState.data._id,
              patient_name: currentAttendance?.patient_name,
              birth_date: currentAttendance?.birthdate,
              address_header: currentAttendance?.address_header,
              address_footer: currentAttendance?.address_footer,
              gender: currentAttendance?.gender,
              mother_name: currentAttendance.mother_name,
              telemedicine_attendance_id: currentAttendance?.id_attendance,
              age: currentAttendance?.age,
              sus_card: currentAttendance?.sus_card,
              health_insurance: currentAttendance?.health_insurance,
              origin: currentAttendance?.origin,
              date_impress: currentAttendance?.date_impress,
              password_panel: currentAttendance?.password_panel,
            })
          );
          navigate("/telemedicine");
        }
        if (!telemedicineAttendancesState.loading) {
          setRefetch(true);
        }
      }
    },
  };

  const personalCard = {
    card: "Dados Pessoais",
    info: ["Dados Pessoais"],
  };
  const planCard = {
    card: "Plano e Internação",
    info: ["Dados de atendimento", "Dados do Plano"],
  };

  const gridPropsPlan = {
    lg: 6,
    xl: 6,
    sx: 6,
    md: 6,
  };

  const disableActions = (attendance, action) => {
    const attendanceInState =
      attendance?.id_attendance.toString() ===
      telemedicineAttendancesState?.meet?.external_ids?.attendance.toString();

    if (action === "finish" && userState?.data?.council !== "CRM") {
      return true;
    }
    if (userState?.data?.council !== "CRM" && action === "create") {
      return true;
    }
    if (
      action === "create" &&
      (!!attendance?.room_id ||
        telemedicineAttendancesState.meet?.room?.id ||
        userState?.data?.council !== "CRM")
    ) {
      return true;
    }
    if (action === "meet" && attendance?.room_id) {
      return false;
    }
    if (action !== "create" && attendanceInState) {
      return false;
    }
    if (action === "meet" && !attendance?.room_id) {
      return true;
    }
    if (action === "create" && attendanceInState) {
      return true;
    }
    if (action === "finish" && !attendance?._id) {
      return true;
    }
    return false;
  };

  const displayOptions: {
    name: string;
    type: ITelemedicineDocs;
    icon: React.ReactNode;
    onClick: () => void;
  }[] = [
    {
      name: "Ficha de Atendimento",
      type: "InitialCare",
      icon: (
        <PatientIcon
          noCircle
          fill={theme.palette.common.white}
          width={"30px"}
          height={"30px"}
        />
      ),
      onClick: () => {
        if (existsDocumentCanceledInitialCare) {
          toast.error("Cancele o último documento para criar um novo!");
        } else {
          setOpenDialog(true);
          setCreateDocumentInCall(true);
          setDialogElement({
            title: "Ficha de Atendimento",
            element: (
              <InitialCare
                sx={{ maxWidth: 400, width: "100%" }}
                inCall
                setOpenCall={setOpenDialog}
                createNewDocument={createDocumentInCall}
                setCreateNewDocument={setCreateDocumentInCall}
                setRefetch={setRefetch}
                telemedicineId={telemedicineId}
              />
            ),
          });
        }
      },
    },
    {
      name: "Receituário Simples",
      type: "SimplePrescription",
      icon: (
        <EvolutionIcon
          width={"30px"}
          height={"30px"}
          fill={theme.palette.common.white}
        />
      ),
      onClick: () => {
        if (existsDocumentCanceledSimple) {
          toast.error("Cancele o último documento para criar um novo!");
        } else {
          setOpenDialog(true);
          setDialogElement({
            title: "Receituário Simples",
            element: (
              <Prescription
                inCall
                setOpenCall={setOpenDialog}
                createNewDocument={createDocumentInCall}
                setCreateNewDocument={setCreateDocumentInCall}
                telemedicineId={telemedicineId}
                setRefetch={setRefetch}
                type={"simple"}
              />
            ),
          });
        }
      },
    },
    {
      name: "Receituário Especial",
      type: "SpecialPrescription",
      icon: (
        <FormIcon
          width={"30px"}
          height={"30px"}
          fill={theme.palette.common.white}
        />
      ),
      onClick: () => {
        if (existsDocumentCanceledSpecial) {
          toast.error("Cancele o último documento para criar um novo!");
        } else {
          setOpenDialog(true);
          setDialogElement({
            title: "Receituário Especial",
            element: (
              <Prescription
                inCall
                setOpenCall={setOpenDialog}
                createNewDocument={createDocumentInCall}
                setCreateNewDocument={setCreateDocumentInCall}
                telemedicineId={telemedicineId}
                setRefetch={setRefetch}
                type={"special"}
              />
            ),
          });
        }
      },
    },
    {
      name: "Atestado do dia",
      type: "MedicalCertificate",
      icon: (
        <AttestIcon
          width={"30px"}
          height={"30px"}
          fill={theme.palette.common.white}
        />
      ),
      onClick: () => {
        if (existsDocumentCanceledMedicalCertificate) {
          toast.error("Cancele o último documento para criar um novo!");
        } else {
          setOpenDialog(true);
          setDialogElement({
            title: "Atestado do dia",
            element: (
              <MedicalCertificate
                sx={{ maxWidth: 400, width: "100%" }}
                inCall
                setOpenCall={setOpenDialog}
                createNewDocument={createDocumentInCall}
                setCreateNewDocument={setCreateDocumentInCall}
                telemedicineId={telemedicineId}
                setRefetch={setRefetch}
              />
            ),
          });
        }
      },
    },
    {
      name: "Documentos Integrados",
      type: "MedicalCertificate",
      icon: (
        <DocumentIcon
          width={"30px"}
          height={"30px"}
          fill={theme.palette.common.white}
        />
      ),
      onClick: () => {
        setOpenDialog(true);
        setDialogElement({
          title: "Documentos Integrados",
          anchor: "bottom",
          element: (
            <Box maxHeight={400}>
              <TabsComponent
                tabs={tabsIntegratedDocuments}
                variant="scrollable"
                tabListSx={{
                  justifyContent: "center",
                }}
              />
            </Box>
          ),
        });
      },
    },
  ];

  const handleRoomId = () => {
    if (userIsDoctor && !currentAttendance?.room_id) {
      return roomId;
    }
    return currentAttendance?.room_id;
  };

  return (
    <Sidebar>
      <Drawer
        sx={{ width: 400 }}
        open={openDialog}
        anchor={dialogElement?.anchor ?? "right"}
        handleClose={() => {
          setOpenDialog(false);
          setDialogElement(null);
        }}
      >
        <Typography variant="h6" mx={3} my={2}>
          {dialogElement?.title}
        </Typography>
        {dialogElement?.element}
      </Drawer>
      <Container sx={{ padding: "20px" }}>
        <SignaturePassword open={digitalSignatureState.isOpen} />
        {openVideoCall ? (
          <Grid
            container
            sx={{ backgroundColor: "#1e1e1e", position: "relative" }}
          >
            <Grid item xs={12}>
              <Box
                bgcolor={theme.palette.primaryDarker.main}
                borderRadius={3}
                sx={{ position: "absolute", top: 20, left: 25, padding: 1 }}
              >
                <THCStype1 fill={"#FFF"} height={30} width={80} />
              </Box>
              <JitsiMeeting
                domain={telemedicineUrl}
                roomName={handleRoomId()}
                onReadyToClose={() => setOpenVideoCall(false)}
                configOverwrite={{
                  startWithAudioMuted: true,
                  disableModeratorIndicator: true,
                  startScreenSharing: true,
                  enableEmailInStats: false,
                }}
                userInfo={
                  userIsDoctor
                    ? {
                        displayName: userState.data.name,
                        email: userState.data.email,
                      }
                    : {
                        displayName: currentAttendance?.patient_name,
                        email: currentAttendance?.email,
                      }
                }
                getIFrameRef={(iframeRef) => {
                  iframeRef.style.height = "600px";
                  // eslint-disable-next-line no-param-reassign
                }}
              />
            </Grid>
            {userIsDoctor && (
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  position: "absolute",
                  right: "3%",
                  height: "90%",
                }}
              >
                {displayOptions.map((option) => (
                  <Tooltip title={option.name} placement="left">
                    <IconButton
                      sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                      }}
                      onClick={() => {
                        setDocumentType(option.type);
                        if (!telemedicineAttendancesState.loading) {
                          option.onClick();
                        }
                      }}
                    >
                      {option.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              </Grid>
            )}
          </Grid>
        ) : (
          <>
            <Typography variant="h5" fontWeight={700} mb={5} color="primary">
              Overview de Paciente
            </Typography>
            <Grid container sx={{ background: "rgb(245, 245, 245)" }}>
              <Header
                content={content}
                attendance={currentAttendance}
                attendanceType="telemedicine"
                actions
                actionsTypes={["create", "meet", "finish"]}
                disableActions={disableActions}
              />
              <Grid
                container
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  paddingY: 1,
                  width: "calc(100% - 68px)",
                  margin: "0 auto",
                }}
              >
                <CardInfo
                  content={content}
                  tittle={personalCard}
                  alergicIs={true}
                  gridProps={gridPropsPlan}
                  integration={integration}
                />

                <CardInfo
                  content={content}
                  tittle={planCard}
                  alergicIs={false}
                  gridProps={gridPropsPlan}
                  integration={integration}
                />
              </Grid>
              <Container sx={{ paddingBottom: 3, marginLeft: 2 }}>
                <TabsComponent
                  tabs={tabs}
                  variant="scrollable"
                  handleClick={(tab) => {
                    setCreateDocument(false);
                    if (tab.label === "Receituário Simples") {
                      setDocumentType("SimplePrescription");
                    } else if (tab.label === "Receituário Especial") {
                      setDocumentType("SpecialPrescription");
                    } else if (tab?.type === "InitialCare") {
                      setDocumentType("InitialCare");
                    } else if (tab?.type === "MedicalCertificate") {
                      setDocumentType("MedicalCertificate");
                    }
                  }}
                />
              </Container>
            </Grid>
          </>
        )}
        <ButtonTabs buttons={buttons} canEdit={false} />
      </Container>
    </Sidebar>
  );
}
