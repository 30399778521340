import { Box, Button, Grid, SxProps, Typography } from "@mui/material";
import React, { useState } from "react";
import AccordionComponent from "../../../../../components/AccordionComponent";
import DocumentIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useForm } from "react-hook-form";
import TextField from "../../../../../components/FormFields/TextField";
import theme from "../../../../../theme/theme";
import DateField from "../../../../../components/FormFields/DateField";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import {
  loadRequestCancelDocument,
  loadRequestCreateDocument,
} from "../../../../../store/ducks/telemedicineAttendances/actions";
import { format, parseISO } from "date-fns";
import LOCALSTORAGE from "../../../../../helpers/constants/localStorage";
import PaginatedAutocompleteField from "../../../../../components/FormFields/PaginatedAutocompleteField";
import { apiSollar } from "../../../../../services/axios";
import { toast } from "react-toastify";

interface IMedicalCertificateProps {
  telemedicineId: string;
  setRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
  createNewDocument: boolean;
  setCreateNewDocument?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCall?: React.Dispatch<React.SetStateAction<boolean>>;
  inCall?: boolean;
  sx?: SxProps;
}

async function getCIDs(params?: any) {
  try {
    const response = await apiSollar.get("/cid/integration/show", {
      params,
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
}

export default function MedicalCertificate({
  telemedicineId,
  setRefetch,
  createNewDocument = false,
  setCreateNewDocument,
  setOpenCall,
  inCall,
  sx,
}: IMedicalCertificateProps) {
  const [cancelDocument, setCancelDocument] = useState(false);
  const userLogged = localStorage.getItem(LOCALSTORAGE.USER_ID);
  const dispatch = useDispatch();

  const telemedicineAttendancesState = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      days: null,
      date: null,
      open_field: "",
      cid: null,
    },
  });

  const cancelForm = useForm({
    defaultValues: {
      canceled_reason: "",
    },
  });

  return (
    <Box sx={sx}>
      {createNewDocument ? (
        <Grid
          container
          spacing={1.5}
          sx={{
            backgroundColor: theme.palette.whiteBackgroud.main,
            color: theme.palette.text.primary,
            paddingX: 3,
            paddingY: 2,
            minHeight: "48px",
          }}
          component="form"
          onSubmit={handleSubmit((values) => {
            let cid = `${values.cid.cid_id} - ${values.cid.name}`;
            delete values.cid
            dispatch(
              loadRequestCreateDocument({
                content: {...values, cid},
                document_type: "MedicalCertificate",
                telemedicine_attendance_id: telemedicineId,
              })
            );
            if (inCall) {
              setOpenCall(false);
            } else {
              setCreateNewDocument(false);
            }
            setRefetch(true);
            reset();
          })}
        >
          <Grid item xs={inCall ? 12 : 6}>
            <TextField
              control={control}
              name="days"
              type="number"
              label="Dias:"
              required
            />
          </Grid>
          <Grid item xs={inCall ? 12 : 6}>
            <DateField
              name="date"
              label="A partir de:"
              control={control}
              required
              inputProps={{ fullWidth: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Observações:"
              control={control}
              name="open_field"
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <PaginatedAutocompleteField
              control={control}
              required
              label="CID"
              name="cid"
              queryKey="cids"
              filterKey="describe"
              service={async (params) => {
                const regex = /^(?=.*[A-Z])(?=.*\d).+$/;
                let code, describe;

                if (!regex.test(params.describe)) {
                  describe = params.describe;
                } else {
                  code = params.describe;
                  delete params.describe;
                }
                const customParams = { describe, code, ...params };
                return getCIDs(customParams);
              }}
              AutocompleteProps={{
                getOptionLabel: (option: any) =>
                  `${option.cid_id ? option.cid_id + " -" : ""} ${option.name}`,
                noOptionsText: "Sem resultados. Tente outra busca"
              }}
              optionLabelKey="name"
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="start">
            <Button type="submit" variant="contained">
              Salvar
            </Button>
          </Grid>
        </Grid>
      ) : (
        telemedicineAttendancesState?.documentList?.items?.medicalCertificate?.map(
          (document) => {
            const documentCanceled = document?.canceled
              ? { textDecoration: "line-through" }
              : { textDecoration: "none" };
            return (
              <AccordionComponent
                configs={[
                  {
                    title: (
                      <Box display="flex" gap={0.3}>
                        <DocumentIcon />
                        <Typography sx={documentCanceled}>
                          {format(
                            parseISO(document?.created_at),
                            "dd/MM/yyyy 'às' HH:mm"
                          )}
                        </Typography>
                      </Box>
                    ),
                    body: (
                      <Box>
                        <Box display="flex" gap={0.4} sx={documentCanceled}>
                          <Typography fontWeight={550}>Dias:</Typography>
                          <Typography>{document?.content?.days}</Typography>
                        </Box>
                        <Box display="flex" gap={0.4} sx={documentCanceled}>
                          <Typography fontWeight={550}>A partir:</Typography>
                          <Typography>
                            {document?.content?.date &&
                              format(
                                parseISO(document?.content?.date),
                                "dd/MM/yyyy"
                              )}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={0.4} sx={documentCanceled}>
                          <Typography fontWeight={550}>
                            Observações:{" "}
                          </Typography>
                          <Typography>
                            {document?.content?.open_field}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={0.4} sx={documentCanceled}>
                          <Typography fontWeight={550}>CID:</Typography>
                          <Typography>{document?.content?.cid}</Typography>
                        </Box>
                        {document?.canceled_at && document?.canceled_reason && (
                          <>
                            <Box display={"flex"} gap={0.4}>
                              <Typography fontWeight={550}>
                                Cancelado em:
                              </Typography>
                              <Typography>
                                {format(
                                  parseISO(document?.canceled_at),
                                  "dd/MM/yyyy 'às' HH:mm"
                                )}
                              </Typography>
                            </Box>
                            <Box display={"flex"} gap={0.4}>
                              <Typography fontWeight={550}>
                                Razão do Cancelamento:
                              </Typography>
                              <Typography>
                                {document?.canceled_reason}
                              </Typography>
                            </Box>
                          </>
                        )}
                        {document?.created_by == userLogged &&
                          !document.canceled && (
                            <Button
                              sx={{ width: 110, mt: 1.5 }}
                              variant="contained"
                              color={cancelDocument ? "primary" : "error"}
                              onClick={() => setCancelDocument(!cancelDocument)}
                            >
                              {cancelDocument ? "Voltar" : "Cancelar"}
                            </Button>
                          )}
                        {cancelDocument && document?.created_by == userLogged && (
                          <Grid
                            sx={{
                              backgroundColor:
                                theme.palette.whiteBackgroud.main,
                              color: theme.palette.text.primary,
                              paddingX: 3,
                              paddingY: 3,
                              minHeight: "48px",
                            }}
                            container
                            spacing={2}
                            component="form"
                            onSubmit={cancelForm.handleSubmit((values) => {
                              dispatch(
                                loadRequestCancelDocument({
                                  canceled_reason: values.canceled_reason,
                                  _id: document._id,
                                })
                              );
                              setCancelDocument(!cancelDocument);
                              setRefetch(true);
                              cancelForm.reset();
                            })}
                          >
                            <Grid item xs={12}>
                              <TextField
                                label="Razão do cancelamento"
                                control={cancelForm.control}
                                name="canceled_reason"
                                multiline
                                minRows={3}
                                required
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                color="error"
                                type="submit"
                                variant="contained"
                                sx={{ maxWidth: 180, width: "100%" }}
                              >
                                Confirmar
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    ),
                  },
                ]}
                defaultOpen={false}
              />
            );
          }
        )
      )}
    </Box>
  );
}
