import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { IAccordionProps } from "./types";
import theme from "../../theme/theme";

function AccordionComponent({ configs, defaultOpen}: IAccordionProps) {
  const [expanded, setExpanded] = useState<string | boolean>(defaultOpen);
  const handleChangePanel = (panel: string) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box>
      {configs?.map((config, index) => (
        <Accordion
          disableGutters={true}
          expanded={defaultOpen || expanded === `panel${index}`}
          key={index}
          onChange={handleChangePanel(`panel${index}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls={`Accordion-${index}`}
            id={`Accordion-${index}`}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              "& div, svg, path, circle, rect": {
                cursor: "pointer",
              },
              borderRadius: "5px",
              borderBottom: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            {config.title || ""}
          </AccordionSummary>
          <AccordionDetails sx={{ paddingY: 2 }}>
            {config.body || ""}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default React.memo(AccordionComponent);
