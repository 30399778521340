import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { checkViewPermission } from "../../../utils/permissions";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../../store";
import { Container, Tab } from "@mui/material";
import TabTittle from "../../../components/Text/TabTittle";
import NoPermission from "../../../components/Erros/NoPermission";
import SESSIONSTORAGE from "../../../helpers/constants/sessionStorage";
import LOCALSTORAGE from "../../../helpers/constants/localStorage";
import Table from "../../../components/Table";
import {
  loadRequest,
  loadCreateAttendance,
  loadRequestUpdateStatus,
} from "../../../store/ducks/revaluation/actions";
import PaginationComponent from "../../../components/Pagination";
import openURL from "../../../utils/openURL";
import {
  IRevaluation,
  IRevaluationRequestParams,
} from "../../../store/ducks/revaluation/types";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function Revaluation() {
  const integration = sessionStorage.getItem(SESSIONSTORAGE.INTEGRATION);
  const [refetch, setRefetch] = useState(false);
  const [valueTab, setValueTab] = useState("revaluate");
  const [statusParam, setStatusParam] = useState<"finished" | "revaluate">("revaluate");
  const dispatch = useDispatch();

  const rightsOfLayoutState = useSelector(
    (state: ApplicationState) => state.layout.data.rights
  );

  const revaluationState = useSelector(
    (state: ApplicationState) => state.revaluation
  );

  const listPayload: IRevaluationRequestParams = {
    page: "1",
    limit: "10",
    status: statusParam,
  };

  useEffect(() => {
    dispatch(loadRequest(listPayload));
    setRefetch(false);
  }, [refetch, valueTab, statusParam]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "finished") {
      setStatusParam("finished");
    } else {
      setStatusParam("revaluate");
    }
    setValueTab(newValue);
  };

  const handleCopy = (revaluation: IRevaluation) => {
    if (!revaluation?.status && !revaluation?._id) {
      dispatch(
        loadCreateAttendance({
          external_attendance_id: revaluation.id_attendance,
          external_patient_id: revaluation.id_medical_record,
        })
      );
      setRefetch(true);
    }
  };

  return (
    <Sidebar>
      {checkViewPermission(
        "revaluation",
        JSON.stringify(rightsOfLayoutState)
      ) ? (
        <Container>
          <TabTittle tittle="Reavaliação" />
          {integration ? (
            <>
              <TabContext value={valueTab}>
                <TabList
                  onChange={handleChangeTab}
                  scrollButtons="auto"
                  sx={{ marginLeft: 3 }}
                >
                  <Tab
                    label={"Para Reavaliar"}
                    id={`tab-${valueTab}`}
                    value="revaluate"
                  />
                  <Tab label={"Finalizados"} id={`tab-${valueTab}`} value="finished" />
                </TabList>
                <TabPanel value={valueTab}>
                  <Table
                    tableCells={[
                      { name: "Atendimento", align: "left" },
                      { name: "Paciente", align: "left" },
                      { name: "Data de Nascimento", align: "center" },
                      { name: "Médico Assistente", align: "left" },
                      { name: "Especialidade", align: "center" },
                      { name: "Data do Atendimento", align: "center" },
                      { name: "Ações", align: "center" },
                    ]}
                    revaluationState={revaluationState}
                    actions
                    actionsTypes={["copy", "meet", "finish"]}
                    actionHandleJoinMeet={(revaluation) => {
                      if (revaluation?.status === "inProgress") {
                        openURL(
                          `${window.location.origin}/call/revaluation/${revaluation?.id_attendance}`
                        );
                        return;
                      }
                      dispatch(
                        loadRequestUpdateStatus({
                          _id: revaluation?._id,
                          status: "inProgress",
                        })
                      );
                      openURL(
                        `${window.location.origin}/call/revaluation/${revaluation?.id_attendance}`
                      );
                      setRefetch(true);
                    }}
                    actionHandleCopy={(revaluation) => handleCopy(revaluation)}
                    actionHandleFinish={(revaluation) => {
                      dispatch(
                        loadRequestUpdateStatus({
                          _id: revaluation?._id,
                          status: "finished",
                        })
                      );
                      setRefetch(true);
                    }}
                    disableActions={(revaluation, action) => {
                      if (valueTab === "1") {
                        return true;
                      }
                      if (revaluation?.status === "finished") {
                        return true
                      }
                      if (!revaluation?._id && action !== "copy") {
                        return true;
                      }
                      if (
                        revaluation?._id &&
                        revaluation?.status === "available" &&
                        action === "finish"
                      ) {
                        return true;
                      }
                      return false;
                    }}
                  >
                    {""}
                  </Table>
                </TabPanel>
              </TabContext>
            </>
          ) : (
            <>
              <Table
                tableCells={[
                  { name: "Atendimento", align: "left" },
                  { name: "Paciente", align: "left" },
                  { name: "Data de Nascimento", align: "center" },
                  { name: "Médico Assistente", align: "left" },
                  { name: "Especialidade", align: "center" },
                  { name: "Resultado de Exame", align: "center" },
                ]}
                revaluationState={revaluationState}
              >
                {""}
              </Table>
            </>
          )}
          <PaginationComponent
            page={revaluationState.data.page?.toString() || "1"}
            rowsPerPage={revaluationState.data.limit?.toString() || "10"}
            totalRows={Number(revaluationState.data?.total || 0)}
            handleFirstPage={() =>
              dispatch(
                loadRequest({
                  page: "1",
                  limit: revaluationState.data.limit?.toString(),
                  ...listPayload,
                })
              )
            }
            handleLastPage={() =>
              dispatch(
                loadRequest({
                  page: Math.ceil(
                    +revaluationState.data?.total /
                      +revaluationState.data?.limit
                  )?.toString(),
                  limit: revaluationState.data.limit.toString(),
                  ...listPayload,
                })
              )
            }
            handleNextPage={() =>
              dispatch(
                loadRequest({
                  page: (+revaluationState.data?.page + 1)?.toString(),
                  limit: revaluationState.data.limit?.toString(),
                  ...listPayload,
                })
              )
            }
            handlePreviosPage={() =>
              dispatch(
                loadRequest({
                  page: (+revaluationState.data?.page - 1)?.toString(),
                  limit: revaluationState.data.limit?.toString(),
                  ...listPayload,
                })
              )
            }
            handleChangeRowsPerPage={(event) => {
              dispatch(
                loadRequest({
                  limit: event.target.value,
                  page: "1",
                  ...listPayload,
                })
              );
            }}
          />
        </Container>
      ) : (
        <NoPermission />
      )}
    </Sidebar>
  );
}
