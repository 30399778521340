import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}
function SvgComponent(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      width={width ? width : 16}
      height={height ? height : 20.468}
      viewBox="0 0 16 20.468"
    >
      <path
        d="M-1009.656,15.125A7.964,7.964,0,0,1-1012,9.468c0-3.428,3.545-7.536,6.1-10.5.581-.679,1.1-1.278,1.51-1.783a.494.494,0,0,1,.7-.077c.02.019.059.038.078.077.406.5.93,1.1,1.51,1.783,2.558,2.963,6.1,7.07,6.1,10.5a7.964,7.964,0,0,1-2.343,5.656A7.962,7.962,0,0,1-1004,17.468,8.048,8.048,0,0,1-1009.656,15.125Zm4.513-15.516c-2.46,2.848-5.868,6.8-5.868,9.86a7.016,7.016,0,0,0,2.052,4.959A7.047,7.047,0,0,0-1004,16.481a6.984,6.984,0,0,0,4.959-2.072,7.049,7.049,0,0,0,2.053-4.959c0-3.081-3.409-7.012-5.87-9.86-.407-.465-.794-.911-1.143-1.336C-1004.349-1.321-1004.736-.876-1005.143-.392Zm-2.935,13.609a5.57,5.57,0,0,1-1.611-3.909.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5,4.49,4.49,0,0,0,1.31,3.183,4.49,4.49,0,0,0,3.183,1.31.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5A5.517,5.517,0,0,1-1008.078,13.217Z"
        transform="translate(1012 3)"
        fill={fill}
        strokeWidth="0.25px"
      />
    </svg>
  );
}

export default SvgComponent;
