import { LaunchOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import DialogMedium from "../../../components/DialogMedium";
import ViewedDocumentHistory from "../viewedDocumentHistory";
import { useDispatch } from "react-redux";
import { loadRequestExamUrl } from "../../../store/ducks/integrationDocuments/actions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { RequestParamsDataSync } from "../../../store/ducks/integrationDocuments/sagas";

export default function LaboratorialExams({ currentAttendance }: any) {
  const [openHistory, setOpenHistory] = React.useState(false);
  const dispatch = useDispatch();

  const integrationDocumentsState = useSelector(
    (state: ApplicationState) => state.integrationDocuments
  );

  const payload: RequestParamsDataSync = {
    data_sync_type: "LAB_EXAM",
    external_patient_id: currentAttendance?.id_patient,
  };

  return (
    <>
      <DialogMedium
        title="Histórico de Visualizações"
        open={openHistory}
        maxWidth="lg"
        handleClose={() => setOpenHistory(false)}
      >
        <ViewedDocumentHistory
          currentAttendance={currentAttendance}
          payload={payload}
        />
      </DialogMedium>
      <Box
        sx={{
          alignItems: "center",
          mt: 3,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            dispatch(
              loadRequestExamUrl({
                ...payload,
                end_date: "2030-01-01",
                initial_date: "2000-01-01",
              })
            );
          }}
        >
          Abrir Exames Laboratorias{" - "}
          <LaunchOutlined />
        </Button>
        <Button variant="outlined" onClick={() => setOpenHistory(true)}>
          Abrir Histórico de Visualizações
        </Button>
      </Box>
    </>
  );
}
