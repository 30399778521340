import React from "react";

interface IPropsSvg {
  fill: any;
  width?: any;
  height?: any;
}

export default function TelemedicineIcon(props: IPropsSvg) {
  const { fill, width, height } = props;

  return (
    <svg
      width={width ? width : "27"}
      height={height ? height : "27"}
      viewBox="0 0 52 52"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.9,10.9H35.7c0-2,0-4.1,0-6.1c0-0.5-0.1-1.1-0.3-1.6C34.7,1.2,33,0,30.7,0c-3.1,0-6.3,0-9.4,0
	c-0.6,0-1.2,0.1-1.7,0.3c-2,0.7-3.2,2.4-3.2,4.5c0,1.8,0,3.6,0,5.3c0,0.2,0.1,0.6-0.1,0.8h-0.4H3c-1.1,0.2-2,0.9-2.6,2
	C0.1,13.5,0,14.3,0,15.1v24.9c0,1,0.2,1.9,0.7,2.7c0.8,1.2,2,1.7,3.4,1.7h17.2c0.3,0,0.6-0.1,0.8,0.1c0.1,0.6,0.1,2.4,0,2.9
	c-0.3,0.1-0.6,0.1-0.9,0.1c-1.8,0.1-3.2,1.3-3.4,3c-0.2,1.1,0.3,1.6,1.4,1.6c4.5,0,9,0,13.5,0c1.1,0,1.6-0.5,1.4-1.7
	c-0.3-1.7-1.7-3-3.5-3c-0.3,0-0.6,0.1-0.9-0.1v-2.8l0,0c0.3-0.2,0.6-0.1,0.8-0.1H42c2,0,4,0,6,0c1.7,0,2.9-0.7,3.7-2.3
	c0.3-0.7,0.4-1.4,0.4-2.2V14.7C51.9,12.6,50.7,11.3,48.9,10.9z M18,8.5c0-1.2,0-2.5,0-3.7c0-1.9,1.3-3.2,3.1-3.2c3.2,0,6.5,0,9.8,0
	c1.9,0,3.2,1.4,3.2,3.3v3.5c0,0.2,0,0.4-0.1,0.7l0,0c-0.9,0-1.7-0.4-2.5-0.8c-0.8-0.4-1.5-1-2.1-1.7c-0.7-0.8-1.2-0.8-2-0.3
	c-2.6,1.7-5.4,2.6-8.5,2.9c-0.2,0-0.4,0-0.5,0c-0.1,0-0.2,0-0.2-0.1C17.9,8.9,18,8.7,18,8.5z M18.1,10.8c3.5-0.2,6.9-1.2,10-3.1
	c0.8,0.9,1.7,1.6,2.7,2.1c1,0.5,2.1,0.8,3.2,1c0,0,0,0,0,0.1c0.5,4.1-0.6,7.8-4.8,9.7c-4.8,2.1-10.3-1-11.1-6.2
	c-0.1-1-0.1-2-0.1-3.1C18,11.1,18,11,18.1,10.8C18.1,10.9,18.1,10.9,18.1,10.8z M23.1,22.4c1.1,0.2,2,0.4,3,0.4c1,0,1.9-0.2,2.8-0.4
	c0.2,0.5,0,1,0.2,1.4c0.1,0.5,0.3,0.9,0.6,1.2l-3.5,3.6c-1.1-1.1-2.3-2.4-3.6-3.6C23,24.3,23.1,23.4,23.1,22.4z M9.4,39.6H4.8
	c-0.1-0.3-0.1-0.5-0.1-0.7c0-0.5,0-1,0-1.5v-4.6c0-5.5,0-11,0-16.5c0-0.3-0.1-0.6,0.1-0.8h11.9c0.8,2.8,2.4,4.8,4.8,6.2
	c0,0.5,0,0.9,0,1.4c0,0.7-0.2,0.9-0.8,1.2c-2.1,0.7-4.2,1.5-6.4,2.1c-3.1,0.9-4.9,3.3-4.7,6.4c0.1,2,0,4,0,6
	C9.5,39.1,9.6,39.3,9.4,39.6z M40.7,39.6H11.3c-0.2-0.2-0.1-0.4-0.1-0.6c0-2.3,0-4.6,0-6.9c0-1.7,1.1-3.3,2.7-3.9
	c0.5-0.2,1-0.3,1.4-0.5c0.1,0,0.2,0,0.3,0.1c-0.1,0.9-0.1,1.8,0.1,2.7c-0.1,0.2-0.3,0.3-0.4,0.3c-1.6,0.7-2.4,1.9-2.4,3.6
	c0,0.8,0,1.6,0,2.4c0,1.2,1.3,1.9,2.4,1.4c0.3-0.2,0.5-0.4,0.4-0.7c0-0.3-0.2-0.5-0.5-0.7c-0.2-0.1-0.5,0-0.7-0.2
	c0-0.9-0.2-1.9,0.1-2.9c0.3-1,1.5-1.7,2.4-1.5c1.2,0.2,2,1.1,2,2.2c0,0.7,0,1.4,0,2.1c-0.2,0.2-0.4,0.2-0.6,0.2
	c-0.4,0.1-0.6,0.4-0.6,0.8c0,0.4,0.2,0.6,0.5,0.7c0.5,0.2,1.1,0.1,1.6-0.1c0.4-0.2,0.7-0.6,0.7-1.1c0-1,0-2,0-2.9
	c0-1.5-1.2-2.9-2.7-3.3c-0.2,0-0.4,0-0.5-0.2c-0.4-1.1-0.3-2.2,0-3.5c1-0.5,2.2-0.8,3.2-1.2c0.3,0.1,0.5,0.3,0.7,0.5
	c1.2,1.2,2.3,2.4,3.5,3.5c0.9,0.8,1.6,0.8,2.4,0c1.2-1.2,2.3-2.3,3.5-3.5c0.1-0.1,0.2-0.3,0.4-0.5h0c1.4,0.4,2.6,0.8,3.8,1.3
	c0.5,1.4,0.6,2.7,0,4.1c-2.3,0.9-3.2,2.3-2.9,4.2c0.2,1.5,1.6,2.8,3.3,2.9c1.4,0.1,3-0.7,3.5-2.4c0.4-1.3,0.3-3.2-2.2-4.5
	c0.1-0.6,0.1-1.2,0.2-1.8c0.1-0.6-0.1-1.2-0.1-1.8c1.8,0.4,3.2,1.3,3.8,3c0.1,0.4,0.2,0.8,0.2,1.1v7C40.9,39.2,40.9,39.4,40.7,39.6z
	 M35.6,32.9c1,0,1.9,0.8,2,1.9c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9S34.5,32.9,35.6,32.9z M47.3,39.6h-4.7
	c-0.2-0.2-0.1-0.5-0.1-0.7c0-2-0.1-4,0-6c0.1-3.1-1.6-5.5-4.7-6.5c-2.2-0.6-4.3-1.4-6.4-2.1c-0.7-0.2-0.8-0.5-0.8-1.1v-1.4
	c1.2-0.7,2.2-1.5,3.1-2.6c0.8-1.1,1.4-2.3,1.8-3.6h11.9V39.6z"
        fill={fill}
      />
    </svg>
  );
}
