import { action } from "typesafe-actions";
import {
  OpinionTypes,
  OpinionInterfaceList,
  LoadRequestParams,
  OpinionInterface,
  Especialities,
  Priorities,
  CreateOpinion
} from "./types";

/** lista de solicitação do parecer */
export const loadRequest = (params: LoadRequestParams = {}) =>
  action(OpinionTypes.LOAD_REQUEST, { params });

export const loadSuccess = (data: OpinionInterfaceList) =>
  action(OpinionTypes.LOAD_SUCCESS, { data });

export const loadFailure = () => action(OpinionTypes.LOAD_FAILURE);

/** dados da solicitação do parecer */
export const loadOpinionRequest = (params: {id: string}) =>
  action(OpinionTypes.LOAD_OPINION_REQUEST, { params });

export const loadOpinionSuccess = (data: OpinionInterface) =>
  action(OpinionTypes.LOAD_OPINION_SUCCESS, { data });

export const loadOpinionFailure = () => action(OpinionTypes.LOAD_OPINION_FAILURE);

/** select de especialidades do parecer */
export const loadSelectSpecialityRequest = (type: string) =>
  action(OpinionTypes.LOAD_SELECT_SPECIALITY_REQUEST, type);

export const loadSelectSpecialitySuccess = (data: Especialities) =>
  action(OpinionTypes.LOAD_SELECT_SPECIALITY_SUCCESS, { data });

export const loadSelectSpecialityFailure = () => action(OpinionTypes.LOAD_SELECT_SPECIALITY_FAILURE);

/** select de prioridades do parecer */
export const loadSelectPriorityRequest = () =>
  action(OpinionTypes.LOAD_SELECT_PRIORITY_REQUEST, { });

export const loadSelectPrioritySuccess = (data: Priorities) =>
  action(OpinionTypes.LOAD_SELECT_PRIORITY_SUCCESS, { data });

export const loadSelectPriorityFailure = () => action(OpinionTypes.LOAD_SELECT_SPECIALITY_FAILURE);

/** criação de um parecer */
export const createOpinionRequest = (data: CreateOpinion) =>
  action(OpinionTypes.CREATE_OPINION_REQUEST, { ...data });

export const createOpinionRequestSuccess = (data: CreateOpinion) =>
  action(OpinionTypes.CREATE_OPINION_REQUEST_SUCCESS, { ...data });

export const createOpinionRequestFailure = () => action(OpinionTypes.CREATE_OPINION_REQUEST_FAILURE);

export const cleanAction = () => action(OpinionTypes.CLEAN);
